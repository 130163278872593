import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';
import FacilityList from '@/components/FacilityList.vue';
import NavigationPage from '@/components/NavigationPage.vue';
import InformationHub from '@/components/InformationHub.vue';
import NewsArticles from '@/components/NewsArticles.vue';
import FAQ from '@/components/FAQ.vue';
import BestPractices from '@/components/BestPractices.vue';
import AboutUs from '@/components/AboutUs.vue';
import UserLogin from '@/components/UserLogin.vue'; // Updated import
import EpsTool from '@/components/EpsTool.vue';
import DataInsights from '@/components/DataInsights.vue';  // Correct import
import CafePost from '@/components/CafePost.vue';          // Correct import
import PackagingPost from '@/components/PackagingPost.vue'; // Correct import
import ImageUpload from '@/components/ImageUpload.vue';    // Correct import
import EcoSummary from '@/components/EcoSummary.vue';
import EcoSolutions from '@/components/EcoSolutions.vue';
import SimulateWaste from '@/components/SimulateWaste.vue';
import NotFound from '@/components/NotFound.vue'; // Added import for 404 page


const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      title: 'Home - Policetyrene'
    }
  },
  {
    path: '/facilityList',
    name: 'FacilityList',
    component: FacilityList,
    meta: {
      title: 'Facility List - Policetyrene'
    }
  },
  {
    path: '/navigate',
    name: 'NavigationPage',
    component: NavigationPage,
    meta: {
      title: 'Navigation Page - Policetyrene'
    }
  },
  {
    path: '/information-hub',
    name: 'InformationHub',
    component: InformationHub,
    meta: {
      title: 'Information Hub - Policetyrene'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      title: 'FAQs - Policetyrene'
    }
  },
  {
    path: '/newsarticles',
    name: 'NewsArticles',
    component: NewsArticles,
    meta: {
      title: 'News Articles - Policetyrene'
    }
  },
  {
    path: '/bestpractices',
    name: 'BestPractices',
    component: BestPractices,
    meta: {
      title: 'Best Practices - Policetyrene'
    }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: 'About Us - Policetyrene'
    }
  },
  {
    path: '/login',  // Add login route
    name: 'UserLogin',  // Updated name
    component: UserLogin,  // Updated component
    meta: {
      title: 'Login - Policetyrene'
    }
  },
  {
    path: '/data-insights',
    name: 'DataInsights',
    component: DataInsights,
    meta: {
      title: 'Data Insights - Policetyrene'
    }
  },
  {
    path: '/cafe-post',
    name: 'CafePost',
    component: CafePost,
    meta: {
      title: 'Cafe Post - Policetyrene'
    }
  },
  {
    path: '/packaging-post',
    name: 'PackagingPost',
    component: PackagingPost,
    meta: {
      title: 'Packaging Post - Policetyrene'
    }
  },
  {
    path: '/eps-tools',
    name: 'EpsTool',
    component: EpsTool,
    meta: {
      title: 'EPS Tools - Policetyrene'
    }
  },
  {
    path: '/plastic-image-upload',
    name: 'ImageUpload',
    component: ImageUpload,  // Register the component
    meta: {
      title: 'Plastic Image Upload - Policetyrene'
    }
  },
  {
    path: '/eco-summary',
    name: 'EcoSummary',
    component: EcoSummary,  // Register the component
    meta: {
      title: 'Eco Summary - Policetyrene'
    }
  },
  {
    path: '/eco-solutions',
    name: 'EcoSolutions',
    component: EcoSolutions,  // Register the component
    meta: {
      title: 'Eco Solutions - Policetyrene'
    }
  },
  {
    path: '/simulate-waste',
    name: 'SimulateWaste',
    component: SimulateWaste,
    meta: {
      title: 'Simulate Waste - Policetyrene'
    },
    beforeRouteLeave: (to, from, next) => {
      const instance = from.matched[0].instances.default;
      if (instance && instance.mapObject) {
        instance.mapObject.remove();
      }
      next();
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 - Policetyrene'
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard to Protect Routes
router.beforeEach((to, from, next) => {
  if (to.name !== 'UserLogin' && localStorage.getItem('authenticated') !== 'true') {
    next({ name: 'UserLogin' }); // Redirect to login if not authenticated
  } else {
    next(); // Allow access
  }
});

export default router;