<template>
  <div class="faq-container">
    <header class = "header">
    <router-link :to="{ name: 'InformationHub' }" class="back-button">
      &larr; Back
    </router-link>
    <h1 class="heading1">Frequently Asked Questions</h1>
    </header>
    <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
      <div @click="toggleFaq(index)" class="faq-question">
        <h3>{{ faq.question }}</h3>
        <span v-if="faq.open">-</span>
        <span v-else>+</span>
      </div>
      <div v-if="faq.open" class="faq-answer" :class="{ 'open': faq.open }">
        <img v-if="faq.image" :src="faq.image" alt="Related image" class="faq-image" />
        <div>
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </div>
    <!-- add  FAQs button -->
  <footer class="site-footer">
    <router-link :to="{ name: 'BestPractices' }" class="diy-tools-button">
      Go to Best Practices
      <i class="fas fa-arrow-right"></i>
    </router-link>
    </footer>
  </div>
</template>

<script>
import faq1 from "@/assets/faq1.png";
import faq2 from "@/assets/faq2.png";
import faq3 from "@/assets/faq3.png";
import faq4 from "@/assets/faq4.png";
import faq5 from "@/assets/faq5.png";
import faq6 from "@/assets/faq6.png";
import faq7 from "@/assets/faq7.png";
import faq8 from "@/assets/cups.png"; // add a new pic

export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [
        {
          question: 'What is Polystyrene Plastic?',
          answer: 'Polystyrene is a type of plastic commonly used for packaging, disposable cups, plates, and insulation materials. It can come in various forms, such as expanded polystyrene (EPS), which is light and used for protective packaging, or as solid polystyrene used in rigid plastic products. Although useful, polystyrene is non-biodegradable and poses significant environmental challenges when improperly disposed of.',
          image: faq1,
          open: false,
        },
        {
          question: 'Why is polystyrene plastic harmful to the aquatic environment?',
          answer: 'Polystyrene is harmful to aquatic environments because it does not break down easily and can remain in water for hundreds of years. Over time, polystyrene fragments into smaller particles, known as microplastics, which can be ingested by marine animals, leading to health issues or death. Additionally, the chemicals in polystyrene can leach into the water, contaminating ecosystems and affecting the overall health of aquatic life.',
          image: faq2,
          open: false,
        },
        {
          question: 'What can we do?',
          answer: 'To mitigate the impact of polystyrene pollution, individuals can adopt responsible waste management practices by recycling polystyrene where possible and reducing its use in daily life. Alternatives such as reusable containers and biodegradable materials are encouraged. Additionally, participating in local clean-up efforts and educating others about the harmful effects of polystyrene can also help protect the environment.',
          image: faq3,
          open: false,
        },
        {
          question: 'How long does polystyrene take to decompose?',
          answer: 'Polystyrene can take hundreds to thousands of years to decompose. Because it is a non-biodegradable plastic, it breaks down into smaller pieces but never fully disappears. These microplastics can persist in the environment and cause long-term harm to aquatic life.',
          image: faq4,
          open: false,
        },
        {
          question: 'Can polystyrene be recycled?',
          answer: 'Yes, polystyrene can be recycled, but it is often challenging due to its lightweight nature and the limited number of facilities that accept it. Specialized recycling centres exist for polystyrene, but the availability varies by location. It\'s important to check if your local recycling centres accept it before disposal.',
          image: faq5,
          open: false,
        },
        {
          question: 'What are some eco-friendly alternatives to polystyrene?',
          answer: 'Some eco-friendly alternatives to polystyrene include biodegradable packaging materials such as paper, cardboard, and plant-based plastics. These materials are not only better for the environment but are also easier to recycle or compost.',
          image: faq6,
          open: false,
        },
        {
          question: 'How does polystyrene pollution affect wildlife in the Yarra River?',
          answer: 'Polystyrene pollution can have devastating effects on wildlife in the Yarra River. Birds, fish, and other animals often mistake small fragments of polystyrene for food, leading to ingestion, which can block their digestive systems, cause malnutrition, and even result in death. Additionally, polystyrene chemicals can leach into the water, harming aquatic ecosystems.',
          image: faq7,
          open: false,
        },
        {
          question: 'How can I reduce polystyrene use in my daily life?',
          answer: 'To reduce polystyrene use, consider opting for reusable or biodegradable alternatives. Avoid purchasing products packaged in polystyrene, bring your own containers for takeout, and encourage local businesses to adopt eco-friendly packaging solutions.',
          image: faq8, // add a new pic
          open: false,
        },
      ],
    };
  },
  methods: {
    toggleFaq(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
  },
};
</script>

<style scoped>
.site-footer {
  margin-top: auto;
  padding: 20px;
  position: relative;
}

.diy-tools-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.diy-tools-button:hover {
  background-color: #5cacee;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.diy-tools-button i {
  margin-left: 8px;
}


.faq-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
}

.faq-button:hover {
  background-color: #007bff;
  transform: translateY(-2px);
}

.faq-button i {
  margin-left: 10px;
}

.faq-container {
  min-height: 100vh;
  background-color: #f0f4f8;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.back-button {
  padding: 10px 15px;
  background-color: #87CEEB;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: #357abd;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.heading1 {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  margin: 0;
  font-size: 2.2em;
  margin-left:26%;
  flex-grow: 1;
}

.faq-item {
  background-color: #ffffff;
  border-radius: 8px;
  margin: 20px auto;
  padding: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  max-width: 800px;
  width: 90%;
  overflow: hidden;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.2em;
  font-family: 'Inter', sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #e9ecef;
}

.faq-question h3 {
  margin: 0;
  font-weight: 600;
  color: #2c3e50;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  font-family: 'Inter', sans-serif;
}

.faq-answer.open {
  max-height: 1000px;
  padding: 20px;
}

.faq-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-answer p {
  color: #4a5568;
  margin: 0;
  line-height: 1.6;
  font-size: 1.1em;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .back-button {
    margin-bottom: 10px;
  }

  .heading1 {
    font-size: 2em;
    text-align: left;
  }

  .faq-item {
    width: 95%;
  }

  .faq-question {
    font-size: 1.1em;
    padding: 15px;
  }

  .faq-answer.open {
    padding: 15px;
  }
}
</style>