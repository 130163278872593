<template>
  <div class="data-insight-container has-background" @wheel="handleScroll">
    <div class="main-content2">
      <div class="side-navigation">
        <ul>
          <li
            v-for="(section, index) in sections"
            :key="section.name"
            :class="{ active: activeSection === index }"
            @click="setActiveSection(index)"
          >
            {{ section.name }}
          </li>
        </ul>
      </div>

      <div class="section-content2" ref="sectionContent">
        <transition name="fade" mode="out-in">
          <component 
            :is="currentComponent" 
            :key="activeSection"
            :geojsonData="geojsonData"
            :latitude="latitude"
            :longitude="longitude"
          ></component>
        </transition>
        
        <!-- Scroll indicator -->
        <div v-if="activeSection !== sections.length - 1" class="scroll-indicator">
          <el-icon :size="32" class="scroll-icon"><Mouse /></el-icon>
          <span>Scroll to navigate</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { ElIcon } from 'element-plus'
import { Mouse } from '@element-plus/icons-vue'
import IntroductionSection from './sections/IntroductionSection.vue'
import ScaleProblemSection from './sections/ScaleProblemSection.vue'
import ImpactSection from './sections/ImpactSection.vue'
import SolutionSection from './sections/SolutionSection.vue'
import BeAPartSection from './sections/BeAPartSection.vue'
import axios from 'axios'

export default {
  name: 'DataInsights',
  components: {
    IntroductionSection,
    ScaleProblemSection,
    ImpactSection,
    SolutionSection,
    BeAPartSection,
    ElIcon,
    Mouse
  },
  setup() {
    const activeSection = ref(0)
    const sections = [
      { name: "Introduction", component: IntroductionSection },
      { name: "Scale Problem", component: ScaleProblemSection },
      { name: "Waste Simulation", component: ImpactSection },
      { name: "Impact", component: SolutionSection },
      { name: "Solution", component: BeAPartSection },
    ]
    const latitude = -37.8136
    const longitude = 144.9631
    const geojsonData = ref(null)
    const isScrolling = ref(false)

    const handleScroll = (event) => {
      if (isScrolling.value) return
      isScrolling.value = true
      setTimeout(() => {
        isScrolling.value = false
      }, 1000) // Adjust this value to control scroll sensitivity

      if (event.deltaY > 0 && activeSection.value < sections.length - 1) {
        activeSection.value++
      } else if (event.deltaY < 0 && activeSection.value > 0) {
        activeSection.value--
      }
    }

    const setActiveSection = (index) => {
      activeSection.value = index
    }

    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.backend.policetyrene.me/api/catchments/')
        if (response.data && response.data.length) {
          geojsonData.value = {
            type: "FeatureCollection",
            features: response.data.map(item => ({
              type: "Feature",
              geometry: item.geometry,
              properties: item,
            })),
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    onMounted(() => {
      fetchData()
    })

    return {
      activeSection,
      sections,
      latitude,
      longitude,
      geojsonData,
      handleScroll,
      setActiveSection,
      currentComponent: computed(() => sections[activeSection.value].component)
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

.scroll-indicator {
  position: absolute;
  bottom: -7%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #122f37;
  font-family: 'Inter', sans-serif;
  animation: bounce 2s infinite;
}

.scroll-icon {
  margin-bottom: 5px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.side-navigation li {
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.side-navigation li:hover {
  background-color: rgba(26, 115, 232, 0.1);
}

.side-navigation li.active {
  background-color: #1a73e8;
  color: white;
}

</style>

<style src="./DataInsights.css"></style>