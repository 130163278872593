<template>
  <div class="about-us-container">
    <!-- About Us Section -->
    <div class="about-us-section">
      <div class="about-box">
        <div class="logo-image">
          <img src="@/assets/policetyrenelogo.png" alt="Project Logo" />
        </div>
        <div class="about-text">
          <h2 class="title_aboutus">About Us</h2>
          <p>
            <b>Policetyrene</b> is an IT for Social Good project developed by Monash 
            University students, dedicated to contributing to a cleaner Yarra 
            River in Melbourne. Our mission is to reduce polystyrene pollution 
            in the Yarra River by empowering local residents with tools and 
            knowledge to take action. Join us in our efforts to protect the Yarra 
            River—every small action contributes to a larger impact!
          </p>
        </div>
      </div>
    </div>

    <!-- Separator Line -->

    <!-- Team Section -->
    <h2 class="title_meet">Meet the Police</h2>
    <div class="team-section">
      <!-- Single team image -->
      <img src="@/assets/team1.png" alt="Team Image" class="team-image" />
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'AboutUs',
};
</script>
  
<style scoped>
.about-us-container {
  padding: 20px;
  background: white;
}

.about-us-section {
  margin-bottom: 20px;
}

/* Style for the rectangular box */
.about-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
}

.about-text {
  flex: 1;
  text-align: left;
}

.logo-image {
  flex: 0 0 auto;
  margin-right: 20px;
}

.logo-image img {
  max-width: 150px;
}

.separator {
  border: 1px solid #ccc;
  margin: 20px 0;
}

.team-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.team-image {
  max-width: 100%;
  border-radius: 8px;
}
  
.title_aboutus {
  font-family: 'Patua One', sans-serif;
  color: #92d3fb;
  text-shadow: none;
  text-align: left;
}

.title_meet {
  font-family: 'Patua One', sans-serif;
  color: #92d3fb;
  text-shadow: none;
}

</style>
