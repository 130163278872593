<template>
  <div class="best-practice-container">
    <header class="header">
      <router-link :to="{ name: 'InformationHub' }" class="back-button">
        &larr; Back
      </router-link>
      <h2 class="title_aboutus">Best Practices For Handling Polystyrene Waste</h2>
    </header>

    <main class="main-content2">
      <div class="card-section">
        <div
          class="best-practice-card image-card"
          @mouseenter="activeCard = 'image'"
          @mouseleave="activeCard = null"
        >
          <img src="@/assets/bp.jpg" alt="Best Practices" class="practice-image" @click="toggleZoom" />
        </div>

        <div
          class="best-practice-card cta-card"
          @mouseenter="activeCard = 'cta'"
          @mouseleave="activeCard = null"
        >
          <div class="cta-content">
            <h3 class="cta-title">Ready to take action?</h3>
            <router-link :to="{ name: 'EcoSolutions' }" class="cta-button">
              Go to 'Eco Solutions'
            </router-link>
            <p class="cta-subtext">Discover ways to protect our rivers and environment</p>
          </div>
        </div>
      </div>
    </main>

    <div v-if="isZoomed" class="zoomed-image" @click="toggleZoom">
      <img src="@/assets/bestPractice.png" alt="Best Practices" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BestPractices',
  data() {
    return {
      isZoomed: false,
      activeCard: null,
    };
  },
  methods: {
    toggleZoom() {
      this.isZoomed = !this.isZoomed;
      document.body.style.overflow = this.isZoomed ? 'hidden' : 'auto';
    },
  },
};
</script>

<style scoped>
.best-practice-container {
  min-height: 80vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back-button, .cta-button {
  padding: 10px 15px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.back-button:hover, .cta-button:hover {
  background-color: #007bff;
  transform: translateY(-2px);
}

.title_aboutus {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  margin: 0;
  font-size: 2.2em;
  text-align: center;
  flex-grow: 1;
}

.main-content2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1800px;
}

.best-practice-card {
  width: 950px;
  height: 580px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  border: 3px solid #87ceeb;
}

.image-card {
  transform: translateX(-250px);
  z-index: 1;
}

.cta-card {
  transform: translateX(250px);
  z-index: 0;
  background-color: #e9f7ff;
  background-image: url('@/assets/ecobg.jpg');
  background-size: cover;
  background-position: center;
}

.best-practice-card:hover {
  transform: scale(1.05) translateX(-250px);
  z-index: 2;
}

.cta-card:hover {
  transform: scale(1.05) translateX(250px);
  z-index: 2;
}

.image-card .practice-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cta-card .cta-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  text-align: center;
  height: 100%;
  background-color: rgba(233, 247, 255, 0.8);
}

.cta-title {
  font-size: 2.5em;
  color: #2c3e50;
  font-family: 'Patua One', sans-serif;
  margin-bottom: 30px;
}

.cta-button {
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3em;
  padding: 15px 30px;
}

.cta-subtext {
  font-size: 1.4em;
  color: #6c757d;
  margin-top: 30px;
  font-family: 'Inter', sans-serif;
}

.zoomed-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: zoom-out;
}

.zoomed-image img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media (max-width: 1800px) {
  .best-practice-card {
    width: 850px;
    height: 520px;
  }
  .image-card {
    transform: translateX(-220px);
  }
  .cta-card {
    transform: translateX(220px);
  }
  .best-practice-card:hover {
    transform: scale(1.05) translateX(-220px);
  }
  .cta-card:hover {
    transform: scale(1.05) translateX(220px);
  }
}

@media (max-width: 1600px) {
  .best-practice-card {
    width: 750px;
    height: 460px;
  }
  .image-card {
    transform: translateX(-190px);
  }
  .cta-card {
    transform: translateX(190px);
  }
  .best-practice-card:hover {
    transform: scale(1.05) translateX(-190px);
  }
  .cta-card:hover {
    transform: scale(1.05) translateX(190px);
  }
  .cta-title {
    font-size: 2.2em;
  }
  .cta-button {
    font-size: 1.2em;
    padding: 12px 25px;
  }
  .cta-subtext {
    font-size: 1.2em;
  }
}

@media (max-width: 1400px) {
  .best-practice-card {
    width: 650px;
    height: 400px;
  }
  .image-card {
    transform: translateX(-160px);
  }
  .cta-card {
    transform: translateX(160px);
  }
  .best-practice-card:hover {
    transform: scale(1.05) translateX(-160px);
  }
  .cta-card:hover {
    transform: scale(1.05) translateX(160px);
  }
}

@media (max-width: 1200px) {
  .card-section {
    flex-direction: column;
    height: auto;
  }

  .best-practice-card {
    position: static;
    width: 90%;
    max-width: 800px;
    margin-bottom: 20px;
    transform: none;
  }

  .image-card,
  .cta-card {
    left: auto;
    right: auto;
  }

  .best-practice-card:hover,
  .cta-card:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-button {
    margin-bottom: 10px;
  }

  .title_aboutus {
    font-size: 1.8em;
    text-align: left;
  }

  .cta-title {
    font-size: 2em;
  }

  .cta-button {
    font-size: 1em;
    padding: 10px 20px;
  }

  .cta-subtext {
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .best-practice-card {
    height: 300px;
  }

  .title_aboutus {
    font-size: 1.6em;
  }

  .cta-title {
    font-size: 1.8em;
  }

  .cta-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  .cta-subtext {
    font-size: 1em;
  }
}
</style>