<template>
  <div id="my-app">
    <!-- Navigation Bar (hidden on Landing Page) -->
    <nav v-if="!isLandingPage">
      <ul class="nav-menu">
        <!-- Logo and Text -->
        <li class="logo-container">
          <img src="@/assets/policetyrenelogo.png" alt="Logo" class="logo" />
          <span class="logo-text">Policetyrene</span>
        </li>

        <!-- Right-aligned Navigation Items -->
        <div class="nav-right">
          <li><router-link to="/" class="nav-link">Home</router-link></li>
          <li><router-link to="/data-insights" class="nav-link">Polystyrene Journey</router-link></li>
          <li class="dropdown">
            <router-link to="/information-hub" class="nav-link">
              Information Hub 
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </router-link>
            <ul class="dropdown-content">
              <li><router-link to="/newsarticles">News Articles</router-link></li>
              <li><router-link to="/faq">FAQs</router-link></li>
              <li><router-link to="/BestPractices">Best Practices</router-link></li>
            </ul>
          </li>
          <li class="dropdown">
            <router-link to="/eco-solutions" class="nav-link">
              Eco Solutions 
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </router-link>
            <ul class="dropdown-content">
              <li><router-link to="/plastic-image-upload">Plastic Recognition</router-link></li>
              <li><router-link to="/facilityList">Facilities Finder</router-link></li>
              <li><router-link to="/eps-tools">DIY Tools for EPS</router-link></li>
            </ul>
          </li>
        </div>
      </ul>
    </nav>

    <!-- Main Content -->
    <main>
      <router-view />
    </main>

    <!-- Footer -->
    <footer class="footer">
      <p>Copyright POLICETYRENE 2024.</p>
    </footer>
  </div>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'

export default {
  name: 'App',
  components: {
    ArrowDown,
  },
};
</script>

<style>
/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#my-app {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
}

nav {
  width: 100%;
  transform: translateX(-40px); 
  z-index: 9999; 
  position: relative; 
}

.nav-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left:40px;
}

.logo {
  width: 50px;
  height: 70px;
  margin-right: 10px;
}

.logo-text {
  font-family: 'Rammetto One', sans-serif;
  font-size: 1.5em;
  color: black;
}

/* Right-aligned navigation items */
.nav-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
}

.nav-link {
  font-family: 'Patua One', sans-serif;
  text-decoration: none;
  color: black;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
}

.nav-link:hover, .nav-link.router-link-active {
  background-color: #87CEEB;
  color: white;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  font-family: 'Patua One', sans-serif;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  max-width: 200px;
  padding: 5px 0;
  border-radius: 8px; 
  z-index: 10;
  transition: all 0.3s ease;
  list-style: none; 
  left: 0; 

}

.dropdown-content a.router-link-active {
  background-color: #87CEEB;
  color: white;
}

.dropdown-content li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px;
  margin: 2px 0;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 8px 12px;
  transition: background-color 0.3s, color 0.3s; 
  border-radius: 5px;
}

.dropdown-content a:hover {
  background-color: #87CEEB;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.footer {
  background-color: #87CEEB;
  color: white;
  text-align: center;
  width: 100%;
  margin-top: auto;
  height: 100px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Patua One', sans-serif;
}

.footer-link {
  color: white;
  text-decoration: none;
  margin-top: 10px;
}

.footer-link:hover {
  text-decoration: underline;
}

.el-icon--right {
  margin-left: 5px;
  transition: transform 0.3s ease;
  font-size: 0.8em;
}

.dropdown:hover .el-icon--right {
  transform: rotate(180deg);
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  nav {
    transform: none; 
  }

  .nav-menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-right {
    margin-left: 0;
  }

  .nav-menu li {
    width: 100%;
    margin-bottom: 10px;
  }

  .dropdown-content {
    position: static;
    display: none;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .nav-link {
    justify-content: space-between;
  }
}
</style>