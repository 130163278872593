<template>
  <div ref="section0" class="content-section2 intro-content">
    <h1 style ="font-family: 'Patua One', sans-serif; font-size: 35px;  margin-top:3%;margin-bottom:10%; z-index: 999;">The Hidden Impact of Daily Polystyrene Packaging</h1>
    <img src="@/assets/5.jpg" alt="Image Description" class="intro-image">
  </div>
</template>


<script>
export default {
  name: 'IntroductionSection',
}
</script>

<style scoped>
.intro-image{
  width: 103vw;
  height: 100vh;
  margin-right: 26%;
  margin-top:-21%;
}
</style>