<template>
   <div class="landing-page">
    <div class="slideshow-container">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="mySlides fade"
        v-show="currentSlide === index"
      >
        <img :src="slide.image" class="slide-image" />
        <div class="gradient-overlay"></div>
        <div class="content-overlay">
          <h1 class="title-overlay">{{ slide.title }}</h1>
          <h2 class="subtitle-overlay">{{ slide.subtitle }}</h2>
          <router-link :to="slide.buttonLink" class="slide-button">
            <span class="btn-text">{{ slide.buttonText }}</span>
          </router-link>
        </div>
      </div>
    </div>
     <!-- Move the threat-section inside the slideshow-container -->
        <div class="threat-section">
          <div class="threat-content">
            <h2>A Growing Threat to Our Waterways</h2>
            <p>Each year, 382 million pieces of polystyrene pollute the Yarra River, harming marine life and contaminating water sources. Lightweight and non-biodegradable, polystyrene breaks into microplastics that persist for decades. Waste from construction, businesses, and residents along the river is the main culprit, making polystyrene a serious environmental hazard that demands urgent attention.</p>
          </div>
          <div class="threat-image">
            <img src="@/assets/problem.png" alt="Yarra River Pollution" />
          </div>
        </div>

    <div class="mission-overlay" id="mission-overlay">
      <h2>Together We Tackle The Problem</h2>
      <p>At Policetyrene, we are committed to empowering the people around the Yarra River to reduce polystyrene pollution
       and protect the river's ecosystem through practical, data-driven solutions. We aim to turn awareness into action, 
       making a positive impact for today and future generations.</p>
    </div>

   <h2 class="centered-heading">Explore Our Services</h2><br><br>
    <section class="collection">
      <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :coverflowEffect="{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }"
        :loop="true"
        :pagination="{ clickable: true }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :modules="modules"
        class="mySwiper"
        @swiper="onSwiper"
      >
        <swiper-slide v-for="(content, index) in swiperContents" :key="index" class="swiper-slide-content">
          <div class="slide-image-container">
            <img :src="content.image" alt="Image" class="slide-image" />
          </div>
          <div class="text-content">
            <h3>{{ content.title }}</h3>
            <p>{{ content.description }}</p>
            <router-link :to="content.link" class="btn">Know More</router-link>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'; // core Swiper
import 'swiper/css/effect-coverflow'; // coverflow effect
import 'swiper/css/pagination'; // pagination
import 'swiper/css/navigation'; // navigation

import { EffectCoverflow, Pagination, Navigation} from 'swiper/modules';


export default {
  name: "LandingPage",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      slides: [
        { image: require("@/assets/slide2.jpg"), title: "Polystyrene Solution for Yarra River", subtitle: "Polystyrene Pollution has polluted our drinking sources and destroyed marine life. We need to take actions. ", buttonText: "Discover Polystyrene Impact", buttonLink: "/data-insights" },
      ],
      currentSlide: 0,
      swiperContents: [
        { image: require("@/assets/river.png"), title: "Polystyrene Journey", description: "Trace polystyrene’s path from production to environmental impact and sustainable solutions.", link: "/data-insights" },
        { image: require("@/assets/info.png"), title: "Information Hub", description: "Access comprehensive resources including news articles, FAQs, and best practices .", link: "/information-hub" },
        { image: require("@/assets/threat-detection.png"), title: "Plastic Recognition", description: "Identify different types of plastic for efficient recycling and disposal.", link: "/plastic-image-upload" },
        { image: require("@/assets/subassets/recyclinglocation.png"), title: "Facilities Finder", description: "Locate nearby recycling centers with our easy-to-use facility finding tool.", link: "/facilityList" },
        { image: require("@/assets/DIY.png"), title: "DIY Tools for Polystyrene", description: "Find essential tools to reduce polystyrene waste and manage fragments in DIY projects.", link: "/eps-tools" },
      ],
    };
  },
 mounted() {
    // Smooth scrolling for anchor links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({ behavior: 'smooth' });
      });
    });
  },
  methods: {
    changeSlide(index) {
      if (index >= this.slides.length) {
        this.currentSlide = 0;
      } else if (index < 0) {
        this.currentSlide = this.slides.length - 1;
      } else {
        this.currentSlide = index;
      }
    },
    onSwiper(swiper) {
      console.log(swiper);
    }
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination, Navigation],
    };
  },
};
</script>
<style scoped>
.swiper-button-next,
.swiper-button-prev {
  color: #0099ff;
}

.swiper-pagination-bullet {
  background-color: #0099ff;
}

.swiper-pagination-bullet-active {
  background-color: #0077ff;
}
</style>

<style scoped src="./LandingPage.css"></style>
<style src="./ExploreSection.css"></style>
