<template>
  <div ref="section1" class="content-section2" id="map-container">
    <h1 style="font-family: 'Patua One', sans-serif; font-size: 35px;">Estimated Polystyrene Pollution on Rivers in Catchment</h1>
    <br><br>
    <l-map 
      :zoom="8" 
      :center="[latitude, longitude]" 
      style="height: 450px; width: 80%; margin: 0 auto; border-radius: 15px; overflow: hidden; position: relative;"
    >
      <l-tile-layer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution="&copy; <a href='https://carto.com/attributions'>CARTO</a>"
      />
      <l-geo-json
        v-if="geojsonData"
        :geojson="geojsonData"
        :options="geojsonOptions"
        @add="onLayerAdd"
        @click="onFeatureClick"
      />
      <div id="legend">
        <div class="legend-title">Polystyrene Pollution</div>
        <div class="legend-bar"></div>
        <div class="legend-scale">
          <ul class="legend-labels">
            <li>0</li>
            <li>38.46</li>
            <li>76.92</li>
          </ul>
        </div>
      </div>
    </l-map>
    <br><br>
    <p>
      The Yarra River catchment area has the second-highest polystyrene pollution with an estimated 
      <span class="highlight">46 tonnes</span> entering the major river system... <span>Click on the map to know more.</span>
    </p>
    <slot name="navigation"></slot>
  </div>
</template>

<script>
import { LMap, LTileLayer, LGeoJson } from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  name: 'ScaleProblem',
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {
    geojsonData: {
      type: Object,
      required: true
    },
    latitude: {
      type: Number,
      default: -37.8136
    },
    longitude: {
      type: Number,
      default: 144.9631
    }
  },
  data() {
    return {
      chartInstance: null,
      mapZoom: 8,  
      mapCenter: [-37.8136, 145.9631],
    };
  },
  computed: {
    geojsonOptions() {
      return {
        style: {
          fillColor: '#E31A1C',
          weight: 1,
          opacity: 1,
          color: 'black',
          fillOpacity: 0.7,
        },
        onEachFeature: (feature, layer) => {
          layer.on('click', () => {
            this.onFeatureClick(layer, feature.properties);
          });
        },
      };
    },
  },
  methods: {
    onLayerAdd(event) {
      event.target.eachLayer(layer => {
        layer.setStyle({
          fillColor: this.getColor(layer.feature.properties.pp_total_year),
          weight: 1,
          opacity: 0.4,
          color: 'black',
          fillOpacity: 0.7,
        });
      });
    },
    onFeatureClick(layer, properties) {
      if (!properties || !properties.pp_jan) {
        console.error('Properties object is missing or incomplete:', properties);
        return;
      }

      const monthlyData = [
        properties.pp_jan, properties.pp_feb, properties.pp_mar,
        properties.pp_apr, properties.pp_may, properties.pp_jun,
        properties.pp_jul, properties.pp_aug, properties.pp_sep,
        properties.pp_oct, properties.pp_nov, properties.pp_dec
      ].map(value => value || 0);

      const totalPollution = (properties.pp_total_year / 1000).toFixed(2);

      const popupContent = `
        <div style="text-align: center; width: 200px;">
          <div style="width: 200px; height: 200px;">
            <canvas id="ppMonthChart" width="200" height="200"></canvas>
          </div>
          <div><strong>${properties.catchment_name}</strong><br>Annual Polystyrene Pollution (ton): <strong>${totalPollution}</strong></div>
        </div>
      `;

      layer.bindPopup(popupContent).openPopup();

      setTimeout(() => {
        const canvas = document.getElementById('ppMonthChart');
        if (canvas) {
          this.renderChart(canvas, monthlyData);
        }
      }, 300);
    },
    renderChart(canvas, data) {
      const ctx = canvas.getContext('2d');
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const dataInTons = data.map(value => value / 1000);

      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            label: '',
            data: dataInTons,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            fill: true,
            tension: 0.4,
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              display: true
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function(tooltipItems) {
                  return tooltipItems[0].label;
                },
                label: function(tooltipItem) {
                  return tooltipItem.raw.toFixed(2) + ' tons';
                }
              }
            }
          }
        }
      });
    },
    getColor(d) {
      return d > 70000 ? '#08306b' :
             d > 50000 ? '#08519c' :
             d > 30000 ? '#2171b5' :
             d > 10000 ? '#4292c6' :
             d > 5000  ? '#6baed6' :
             d > 2000  ? '#9ecae1' :
             d > 1000  ? '#c6dbef' :
                         '#deebf7';
    },
  },
};
</script>
