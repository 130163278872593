<template>
    <div class="best-practice-page">
      <!-- Header Section -->
      <div class="header">
        <router-link :to="{ name: 'BestPractices' }" class="back-button">
          &larr; Back
        </router-link>
        <h2 class="title">Best Practices for Handling Packaging Waste</h2>
      </div>
  
      <!-- Best Practices Section -->
    <div class="best-practices">
      <!-- First Practice (Content Left, Video Right) -->
      <div class="practice-section content-left">
        <div class="text-content">
          <h3>1. Reuse Packaging Foam</h3>
          <p>Consider reusing polystyrene foam for future packaging needs, or offer it to local businesses or organizations that could benefit from it, reducing the demand for new materials.</p>
        </div>
        <div class="media-content">
            <iframe 
                width="250" 
                height="140" 
                src="https://www.youtube.com/embed/OTcKuWqGYlQ?si=4VkVhtFge1q30z4y&amp;start=47&autoplay=1&mute=1" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen>
            </iframe>
        </div>
      </div>

      <!-- Second Practice (Image Left, Content Right) -->
      <div class="practice-section content-right">
        <div class="media-content">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVK0CRmx65ct-QWPpL91xcUACjDwq8wxZD8g&s" alt="Biodegradable Packaging" class="practice-image"/>
        </div>
        <div class="text-content">
          <h3>2. Switch to Biodegradable Packaging</h3>
          <p>Replace polystyrene with biodegradable or recyclable materials for packaging. Many businesses have already adopted materials like cardboard or biodegradable plastic for their packaging needs.</p>
        </div>
      </div>

      <!-- Third Practice (Content Left, Image Right) -->
      <div class="practice-section content-left">
        <div class="text-content">
          <h3>3. Return Polystyrene to Suppliers</h3>
          <p>Some suppliers and manufacturers offer take-back programs where used polystyrene packaging can be returned for proper recycling or reuse. Check with your supplier for such programs.</p>
        </div>
        <div class="media-content">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY_xJ70cG-4NnHRsp7DEzbz8PiAB5lKckDuZy2TCDJEyqSIhg1MH88M3mJDw&s" alt="Return to Supplier" class="practice-image"/>
        </div>
      </div>

      <!-- Fourth Practice (Image Left, Content Right) -->
      <div class="practice-section content-right">
        <div class="media-content">
          <img src="@/assets/recycle_polystyrene.jpeg" alt="Recycle Center" class="practice-image"/>
        </div>
        <div class="text-content">
          <h3>4. Connect to Recycling Centers</h3>
          <p>If reusing or returning polystyrene isn't an option, find a recycling center near you that accepts polystyrene waste. Click below to find a facility near you.</p>
          <router-link to="/facilityList" class="facility-link">
            Find a Facility Near You
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'PackagingPost',
  };
</script>
  

<style scoped>
.best-practice-page {
  padding: 40px 20px;
  background-color: #f8f9fa;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.back-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #87CEEB;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 20px;
  font-family: 'Inter', sans-serif;
}

.back-button:hover {
  background-color: #007BFF;
}

.title {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  font-size: 2.5em;
  margin: 0;
  padding-left: 100px;
  text-align: center;
  flex: 1;
}

.best-practices {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.practice-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.text-content, .media-content {
  flex: 1;
}

.practice-section h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #333;
  font-family: 'Patua One', sans-serif;
}

.practice-section p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
  font-family: 'Inter', sans-serif;
}

.media-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px; /* Fixed height for all media content */
  overflow: hidden;
}

.practice-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

.facility-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #87CEEB;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.facility-link:hover {
  background-color: #007BFF;
}

.content-right {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .best-practice-page {
    padding: 20px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-button {
    margin-bottom: 10px;
  }

  .title {
    font-size: 1.6em;
  }

  .best-practices {
    gap: 40px;
  }

  .practice-section, .content-right {
    flex-direction: column;
    gap: 20px;
  }

  .practice-section h3 {
    font-size: 1.5em;
  }

  .practice-section p {
    font-size: 1em;
  }

  .media-content {
    order: -1;
    height: 200px; /* Slightly smaller height for mobile */
  }
}
</style>