<template>
  <div class="navigation-page">
    <h1 class="navigation-title">
      <span class="nav-prefix">Navigation to </span>
      <span class="destination-name">{{ destinationName }}</span>
    </h1>
    <div class="controls">
      <select v-model="travelMode" class="travel-mode-select" @change="calculateAndDisplayRoute">
        <option value="DRIVING">Driving</option>
        <option value="WALKING">Walking</option>
        <option value="BICYCLING">Bicycling</option>
        <option value="TRANSIT">Transit</option>
      </select>
    </div>

    <div class="navigation-container">
      <div v-if="routeInfo" class="route-info">
        <h3>Route Details</h3>
        <div class="info-item">
          <i class="fas fa-map-marker-alt"></i>
          <div>
            <strong>From:</strong>
            <p>{{ routeInfo.origin }}</p>
          </div>
        </div>
        <div class="info-item">
          <i class="fas fa-flag-checkered"></i>
          <div>
            <strong>To:</strong>
            <p>{{ routeInfo.destination }}</p>
          </div>
        </div>
        <div class="info-item">
          <i class="fas fa-road"></i>
          <div>
            <strong>Distance:</strong>
            <p>{{ routeInfo.distance }}</p>
          </div>
        </div>
        <div class="info-item">
          <i class="fas fa-clock"></i>
          <div>
            <strong>Estimated Time:</strong>
            <p>{{ routeInfo.duration }}</p>
          </div>
        </div>
        <button @click="goBack" class="back-button">
          <i class="fas fa-arrow-left"></i> Back to Facility List
        </button>
      </div>
      <div id="map" class="map-container"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userLocation: { lat: null, lng: null },
      destination: { lat: null, lng: null },
      mapCenter: { lat: -37.8136, lng: 144.9631 },
      destinationName: '',
      travelMode: 'DRIVING',
      directionsRenderer: null,
      map: null,
      routeInfo: null,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const queryParams = new URLSearchParams(window.location.search);
      this.userLocation = {
        lat: parseFloat(queryParams.get('userLat')),
        lng: parseFloat(queryParams.get('userLng')),
      };
      this.destination = {
        lat: parseFloat(queryParams.get('destLat')),
        lng: parseFloat(queryParams.get('destLng')),
      };
      this.destinationName = queryParams.get('destName') || 'Destination';
      this.travelMode = queryParams.get('travelMode') || 'DRIVING';

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 12,
        center: this.userLocation,
      });

      // eslint-disable-next-line no-undef
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);

      this.calculateAndDisplayRoute();
    },
    calculateAndDisplayRoute() {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();

      directionsService.route(
        {
          origin: this.userLocation,
          destination: this.destination,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode[this.travelMode],
        },
        (response, status) => {
          if (status === 'OK') {
            this.directionsRenderer.setDirections(response);
            const route = response.routes[0].legs[0];
            
            // Get address for origin and destination
            geocoder.geocode({'location': this.userLocation}, (results, status) => {
              if (status === 'OK') {
                const originAddress = results[0].formatted_address;
                geocoder.geocode({'location': this.destination}, (results, status) => {
                  if (status === 'OK') {
                    const destinationAddress = results[0].formatted_address;
                    this.routeInfo = {
                      origin: originAddress,
                      destination: destinationAddress,
                      distance: route.distance.text,
                      duration: route.duration.text
                    };
                  }
                });
              }
            });
          } else {
            console.error('Directions request failed due to ' + status);
          }
        }
      );
    },
    goBack() {
      this.$router.push({ name: 'FacilityList' });
    }
  },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');

body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f7f9fc;
  color: #333;
  line-height: 1.6;
}

.navigation-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.navigation-title {
  font-size: 2.2em;
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50;
  font-weight: 300;
  animation: fadeIn 0.8s ease-out;
  font-family: 'Patua One', sans-serif;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.nav-prefix {
  color: #95a5a6;
}

.destination-name {
  color: #3498db;
  font-weight: 500;
}

.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.travel-mode-select {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.3);
}

.travel-mode-select:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.4);
}

.navigation-container {
  display: flex;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.route-info {
  padding: 25px;
  width: 350px;
  border-right: 1px solid #ecf0f1;
  animation: fadeIn 0.5s ease-out;
  display: flex;
  flex-direction: column;
}

.route-info h3 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}

.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.info-item i {
  font-size: 1.5em;
  color: #3498db;
  margin-right: 15px;
  min-width: 25px;
}

.info-item div {
  flex-grow: 1;
}

.info-item strong {
  display: block;
  color: #2c3e50;
  font-weight: 500;
  margin-bottom: 5px;
}

.info-item p {
  margin: 0;
  color: #34495e;
  font-size: 0.9em;
}

.back-button {
  margin-top: auto;
  padding: 12px 20px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button i {
  margin-right: 10px;
}

.back-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.map-container {
  flex-grow: 1;
  height: 500px;
  animation: fadeIn 0.5s ease-out;
}

@media (max-width: 968px) {
  .navigation-container {
    flex-direction: column;
  }

  .route-info {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ecf0f1;
  }

  .map-container {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .navigation-title {
    font-size: 1.8em;
  }

  .travel-mode-select {
    width: 80%;
  }

  .navigation-container {
    border-radius: 0;
  }

  .map-container {
    height: 350px;
  }
}
</style>
