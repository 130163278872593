import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);

app.use(router);
app.use(ElementPlus)

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBzlriqash2F1C4bONe4Jcph5kxWGI2fdg',
    libraries: ['places'],
    language: 'en',
  },
  installComponents: true
});

app.mount('#app');
document.title = 'policetyrene'

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Policetyrene'
  next()
})