<template>
  <div class="full-background">
    <div class="overlay-box">
      <div class="left-side">
        
        <img src="@/assets/policetyrenelogo.png" alt="Logo" class="logo" /> <!-- Replace with your logo URL -->
        
      </div>
      <div class="right-side">
        <h2>Welcome to Policetyrene !!!</h2><br>
        Sign-In to Access: <input type="password" v-model="password" placeholder="Enter password" /><br>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p><br>
        <button @click="checkPassword" class="submit-button">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLogin', // Updated component name
  data() {
    return {
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    checkPassword() {
      const correctPassword = 'policetyrene2024'; // Change this to your desired password
      if (this.password === correctPassword) {
        localStorage.setItem('authenticated', 'true'); // Store a flag in localStorage
        this.$router.push('/'); // Redirect to the main route
      } else {
        this.errorMessage = 'Incorrect password. Please try again.';
      }
    },
  },
  created() {
    // Redirect to home if already authenticated
    if (localStorage.getItem('authenticated') === 'true') {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
.full-background {
  height: 100vh; /* Full viewport height */
  background-image: url('@/assets/login_bg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.8); /* Light transparent overlay */
  display: flex; /* Center the login form */
  justify-content: center;
  align-items: center; 
}

.overlay-box {
  display: flex; /* Use flexbox to create a two-column layout */
  width: 80%; /* Width of the overlay box */
  max-width: 800px; /* Maximum width */
  background-color: rgba(255, 255, 255, 0.4); /* White background with slight transparency */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  margin-left: 40px;
}

.left-side {
  flex: 1; /* Take up half of the space */
  padding: 20px; /* Padding inside the box */
  text-align: center; /* Center text */
}

.right-side {
  margin-top: 80px;
  margin-right: 30px;
  flex: 1; /* Take up half of the space */
  padding: 20px; /* Padding inside the box */
  text-align: center; /* Center text */
}

.logo {
  width: 200px; /* Adjust logo size */
  height: 280px;
  margin-top: 10px; /* Space between heading and logo */
}

input {
  padding: 10px;
  margin: 10px 0; /* Vertical margin only */
  color: black;
}

.submit-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007BFF; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 25px; /* Oval shape */
  transition: background-color 0.3s; /* Transition for hover effect */
}

.submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.error {
  color: red;
  margin-top: 10px;
}
</style>
