<template>
  <div class="eps-assistant">
  <header class="header">
      <router-link :to="{ name: 'EcoSolutions' }" class="back-button">
        &larr; Back 
      </router-link>
      <h2 class="title">DIY Tools Recommendation for Polystyrene Projects</h2>
    </header>
    
    <div class="content-wrapper">
      <div class="image-container left">
        <img src="@/assets/123.jpeg" alt="EPS blocks" class="side-image" />
        <img src="@/assets/arrow.png" alt="Arrow" class="arrow-image" />
        <img src="@/assets/diy1.jpg" alt="EPS model" class="side-image" />
      </div>

      <div class="main-content">
        <div class="tabs">
          <button @click="activeTab = 'cutting'" :class="{ active: activeTab === 'cutting' }">Cutting Tool Selection</button>
          <button @click="activeTab = 'fragment'" :class="{ active: activeTab === 'fragment' }">Fragment Handling</button>
        </div>

        <div v-if="activeTab === 'cutting'" class="tab-content">
          <div class="select-container">
            <label for="projectType">Select Project Type:</label>
            <select v-model="selectedProjectType" @change="updateProjectScale" id="projectType">
              <option value="">Select a project type</option>
              <option v-for="project in projectTypes" :key="project.type" :value="project.type">
                {{ project.type }}
              </option>
            </select>
          </div>
          <div class="select-container">
            <label for="projectScale">Select Project Scale:</label>
            <select v-model="selectedProjectScale" id="projectScale" :disabled="!selectedProjectType">
              <option value="">Select a project scale</option>
              <option v-for="scale in availableScales" :key="scale" :value="scale">
                {{ scale }}
              </option>
            </select>
          </div>
          <div class="description-container">
            <p><strong>Description:</strong> {{ selectedDescription }}</p>
          </div>
          <button @click="getRecommendation" :disabled="!selectedProjectType || !selectedProjectScale">
            Get Recommendation
          </button>
          <div v-if="recommendations" class="recommendations">
            <div v-for="tool in recommendations" :key="tool.name" class="tool-recommendation">
              <h3>Recommended Cutting Tool: <strong>{{ tool.name }}</strong> (<a :href="tool.link" target="_blank" rel="noopener noreferrer">View this tool on Amazon</a>)</h3>
              <p><strong>Suitable for</strong>: {{ tool.suitableFor }}</p>
              <h3>Benefits of the tool:</h3>
              <ul>
                <li v-for="benefit in tool.benefits" :key="benefit.title">
                  <strong>{{ benefit.title }}</strong>: {{ benefit.description }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'fragment'" class="tab-content">
          <div class="select-container">
            <label for="fragmentSize">Select Fragment Size:</label>
            <select v-model="selectedFragmentSize" id="fragmentSize">
              <option value="">Select a size</option>
              <option v-for="size in fragmentSizes" :key="size" :value="size">{{ size }}</option>
            </select>
          </div>
          <div class="select-container">
            <label for="surfaceType">Select Surface Type:</label>
            <select v-model="selectedSurfaceType" id="surfaceType">
              <option value="">Select a surface type</option>
              <option v-for="type in surfaceTypes" :key="type" :value="type">{{ type }}</option>
            </select>
          </div>
          <button @click="getFragmentRecommendation" :disabled="!selectedFragmentSize || !selectedSurfaceType">
            Get Recommendation
          </button>
          <div v-if="fragmentRecommendation" class="recommendations">
            <h3>Recommended Cleaning Tools:</h3>
            <ul>
              <li v-for="tool in fragmentRecommendation.tools" :key="tool.name">
                {{ tool.name }} - 
                <a :href="tool.link" target="_blank" rel="noopener noreferrer">View on Amazon</a>
              </li>
            </ul>
            <h3>Handling Tip:</h3>
            <p>{{ fragmentRecommendation.tip }}</p>
            <h3>Recommended Packaging:</h3>
            <p>{{ fragmentRecommendation.packaging }}</p>
            <h3>Disposal Method:</h3>
            <p v-if="selectedFragmentSize === 'Large Chunks'">
              If clean, take to a local recycling point 
              (<router-link to="/facilityList">view nearby recycling facilities</router-link>). 
              If dirty, dispose of in regular trash.
            </p>
            <p v-else>
              {{ fragmentRecommendation.disposal }}
            </p>
          </div>
        </div>
      </div>
      <div class="image-container right">
        <img src="@/assets/subassets/EPS.png" alt="EPS blocks" class="side-image" />
        <img src="@/assets/arrow.png" alt="Arrow" class="arrow-image" />
        <img src="@/assets/subassets/clean.jpg" alt="EPS model" class="side-image" />
      </div>
    </div>
    <footer class="site-footer">
    <router-link :to="{ name: 'DataInsights' }" class="diy-tools-button">
      Go to Polystyrene Journey
      <i class="fas fa-arrow-right"></i>
    </router-link>
    </footer>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'

export default {
  setup() {
    const activeTab = ref('cutting')
    const selectedProjectType = ref('')
    const selectedProjectScale = ref('')
    const selectedDescription = ref('Please select a project type and scale for more details.')
    const recommendations = ref(null)
    const selectedFragmentSize = ref('')
    const selectedSurfaceType = ref('')
    const fragmentRecommendation = ref(null)

    const projectTypes = [
      { type: 'Home Renovation', scales: ['Small', 'Medium'] },
      { type: 'Model Making', scales: ['Small', 'Medium'] },
      { type: 'Handicrafts', scales: ['Small'] }
    ]

    const fragmentSizes = ['Small Beads', 'Large Chunks']
    const surfaceTypes = ['Hard Floor', 'Carpet', 'Outdoor Surface']

    const descriptions = {
      'Home Renovation': {
        Small: 'Small home projects like wall decoration or simple furniture.',
        Medium: 'Medium-sized home projects like larger furniture or partition walls.',
      },
      'Model Making': {
        Small: 'Small model making, such as DIY crafts or toy projects.',
        Medium: 'Medium-sized models like architectural or exhibition models.'
      },
      'Handicrafts': {
        Small: 'Handicrafts and art projects, using small amounts of EPS.'
      }
    }

    const toolRecommendations = {
      'Home Renovation': {
        Small: [
          {
            name: 'EPS Foam Cutter',
            suitableFor: 'Small-scale or detailed DIY projects',
            benefits: [
              {
                title: 'Precision Cutting',
                description: 'Ideal for small or complex shapes, ensuring neat edges and precise details. This precision helps minimize material waste.'
              },
              {
                title: 'Portability',
                description: 'Lightweight design, suitable for home use, easy to carry and store.'
              },
              {
                title: 'Ease of Use',
                description: 'Suitable for beginners and non-professionals, with intuitive operation.'
              },
              {
                title: 'Waste Reduction',
                description: 'The precise cutting capability reduces unnecessary EPS debris generated during the creation process.'
              }
            ],
            link: 'https://www.amazon.com/s?k=eps+foam+cutter'
          }
        ],
        Medium: [
          {
            name: 'Hot Wire EPS Cutter',
            suitableFor: 'Large-scale industrial applications or extensive DIY projects',
            benefits: [
              {
                title: 'Efficient Cutting',
                description: 'Hot wire technology quickly cuts through large blocks of EPS, suitable for large-scale operations.'
              },
              {
                title: 'Clean Cuts',
                description: 'Hot wire technology reduces debris and dust, keeping the work area clean.'
              },
              {
                title: 'Material Savings',
                description: 'Precise cutting reduces material waste, particularly useful for scenarios that require handling large amounts of material.'
              },
              {
                title: 'Reduced Extra Waste',
                description: 'The melting effect of hot wire cutting results in smoother EPS edges, reducing extra waste produced during the creation process, which is beneficial for environmental protection.'
              }
            ],
            link: 'https://www.amazon.com/s?k=hot+wire+eps+cutter'
          }
        ]
      },
      'Model Making': {
        Small: [
          {
            name: 'EPS Foam Cutter',
            suitableFor: 'Small-scale or detailed DIY projects',
            benefits: [
              {
                title: 'Precision Cutting',
                description: 'Ideal for small or complex shapes, ensuring neat edges and precise details. This precision helps minimize material waste.'
              },
              {
                title: 'Portability',
                description: 'Lightweight design, suitable for home use, easy to carry and store.'
              },
              {
                title: 'Ease of Use',
                description: 'Suitable for beginners and non-professionals, with intuitive operation.'
              },
              {
                title: 'Waste Reduction',
                description: 'The precise cutting capability reduces unnecessary EPS debris generated during the creation process.'
              }
            ],
            link: 'https://www.amazon.com/s?k=eps+foam+cutter'
          }
        ],
        Medium: [
          {
            name: 'Hot Wire EPS Cutter',
            suitableFor: 'Large-scale industrial applications or extensive DIY projects',
            benefits: [
              {
                title: 'Efficient Cutting',
                description: 'Hot wire technology quickly cuts through large blocks of EPS, suitable for large-scale operations.'
              },
              {
                title: 'Clean Cuts',
                description: 'Hot wire technology reduces debris and dust, keeping the work area clean.'
              },
              {
                title: 'Material Savings',
                description: 'Precise cutting reduces material waste, particularly useful for scenarios that require handling large amounts of material.'
              },
              {
                title: 'Reduced Extra Waste',
                description: 'The melting effect of hot wire cutting results in smoother EPS edges, reducing extra waste produced during the creation process, which is beneficial for environmental protection.'
              }
            ],
            link: 'https://www.amazon.com/s?k=hot+wire+eps+cutter'
          }
        ]
      },
      'Handicrafts': {
        Small: [
          {
            name: 'EPS Foam Cutter',
            suitableFor: 'Small-scale or detailed DIY projects',
            benefits: [
              {
                title: 'Precision Cutting',
                description: 'Ideal for small or complex shapes, ensuring neat edges and precise details. This precision helps minimize material waste.'
              },
              {
                title: 'Portability',
                description: 'Lightweight design, suitable for home use, easy to carry and store.'
              },
              {
                title: 'Ease of Use',
                description: 'Suitable for beginners and non-professionals, with intuitive operation.'
              },
              {
                title: 'Waste Reduction',
                description: 'The precise cutting capability reduces unnecessary EPS debris generated during the creation process.'
              }
            ],
            link: 'https://www.amazon.com/s?k=eps+foam+cutter'
          }
        ]
      }
    }

    const fragmentRecommendations = {
      'Small Beads': {
      'Hard Floor': {
        tools: [
          { name: 'Shop Vacuum', link: 'https://www.amazon.com/s?k=shop+vacuum' },
          { name: 'Static-charged Broom', link: 'https://www.amazon.com/s?k=static+charged+broom' },
          { name: 'Rubber-edged Dustpan', link: 'https://www.amazon.com/s?k=rubber+edged+dustpan' }
        ],
        tip: 'Use a static-charged broom to gather beads, then collect with a rubber-edged dustpan for better pickup. Finally, use a shop vacuum for thorough cleaning.',
        packaging: 'Sealed Plastic Bag',
        disposal: 'Place in a sealed plastic bag and dispose of as regular trash.'
      },
      'Carpet': {
        tools: [
          { name: 'High-Powered Vacuum Cleaner', link: 'https://www.amazon.com/s?k=high+powered+vacuum+cleaner' },
          { name: 'Rubber Broom', link: 'https://www.amazon.com/s?k=rubber+broom' }
        ],
        tip: 'Start with a rubber broom to bring beads to the surface, then use a high-powered vacuum cleaner for thorough removal. Multiple passes may be necessary.',
        packaging: 'Sealed Plastic Bag',
        disposal: 'Place in a sealed plastic bag and dispose of as regular trash.'
      },
      'Outdoor Surface': {
        tools: [
          { name: 'Wet/Dry Vacuum', link: 'https://www.amazon.com/s?k=wet+dry+vacuum' },
          { name: 'Stiff Bristle Broom', link: 'https://www.amazon.com/s?k=stiff+bristle+outdoor+broom' },
          { name: 'Leaf Blower', link: 'https://www.amazon.com/s?k=leaf+blower' }
        ],
        tip: 'For hard surfaces, use a stiff bristle broom to gather beads, then collect with a wet/dry vacuum. For larger areas, a leaf blower can help gather beads into piles for easier collection. For porous surfaces, use a wet/dry vacuum directly.',
        packaging: 'Sealed Plastic Bag',
        disposal: 'Place in a sealed plastic bag and dispose of as regular trash. If a large quantity is collected outdoors, check local regulations for proper disposal methods.'
      }
    },
      'Large Chunks': {
        'Hard Floor': {
          tools: [
            { name: 'Dustpan and Broom', link: 'https://www.amazon.com/s?k=dustpan+and+broom' },
            { name: 'Tongs or Grabber Tool', link: 'https://www.amazon.com/s?k=grabber+tool' },
            { name: 'Large Trash Bags', link: 'https://www.amazon.com/s?k=large+trash+bags' }
          ],
          tip: 'Use tongs or a grabber tool for precise pickup of large pieces. Sweep smaller pieces into a dustpan. Place chunks directly into large trash bags.',
          packaging: 'Large Clear Plastic Bag',
          disposal: 'If clean and your local recycling center accepts EPS, take to a recycling point. If dirty or not accepted locally, dispose of in regular trash.'
        },
        'Carpet': {
          tools: [
            { name: 'Carpet Rake', link: 'https://www.amazon.com/s?k=carpet+rake' },
            { name: 'Dustpan and Brush', link: 'https://www.amazon.com/s?k=dustpan+and+brush' },
            { name: 'Vacuum Cleaner', link: 'https://www.amazon.com/s?k=vacuum+cleaner' }
          ],
          tip: 'Use a carpet rake to bring chunks to the surface. Collect larger pieces with a dustpan and brush, then vacuum thoroughly for smaller fragments.',
          packaging: 'Large Clear Plastic Bag',
          disposal: 'If clean and your local recycling center accepts EPS, take to a recycling point. If dirty or not accepted locally, dispose of in regular trash.'
        },
        'Outdoor Surface': {
          tools: [
            { name: 'Heavy-duty Rake', link: 'https://www.amazon.com/s?k=heavy+duty+rake' },
            { name: 'Shovel', link: 'https://www.amazon.com/s?k=shovel' },
            { name: 'Large Outdoor Trash Bags', link: 'https://www.amazon.com/s?k=large+outdoor+trash+bags' },
            { name: 'Work Gloves', link: 'https://www.amazon.com/s?k=work+gloves' }
          ],
          tip: 'Wear work gloves for protection. Rake chunks into piles, then use a shovel to transfer them into large outdoor trash bags. For very large pieces, break them down into manageable sizes before bagging.',
          packaging: 'Large Clear Plastic Bag or Large Outdoor Trash Bags',
          disposal: 'If clean and your local recycling center accepts EPS, take to a recycling point. If dirty or not accepted locally, dispose of in regular trash. For large quantities, check local regulations or contact waste management services for guidance.'
        }
      }
    }

    const availableScales = computed(() => {
      const selectedProject = projectTypes.find(p => p.type === selectedProjectType.value)
      return selectedProject ? selectedProject.scales : []
    })

    const updateProjectScale = () => {
      selectedProjectScale.value = ''
      recommendations.value = null // Clear recommendations when project type changes
    }

    const getRecommendation = () => {
      if (selectedProjectType.value && selectedProjectScale.value) {
        recommendations.value = toolRecommendations[selectedProjectType.value][selectedProjectScale.value]
      }
    }

    const getFragmentRecommendation = () => {
      if (selectedFragmentSize.value && selectedSurfaceType.value) {
        fragmentRecommendation.value = fragmentRecommendations[selectedFragmentSize.value][selectedSurfaceType.value]
      }
    }

    watch([selectedProjectType, selectedProjectScale], ([newType, newScale]) => {
      if (newType && newScale) {
        selectedDescription.value = descriptions[newType][newScale]
      } else {
        selectedDescription.value = 'Please select both a project type and scale to see a detailed description.'
      }
      recommendations.value = null // Clear recommendations when selections change
    })

    watch([selectedFragmentSize, selectedSurfaceType], () => {
      fragmentRecommendation.value = null // Clear fragment recommendations when selections change
    })

    return {
      activeTab,
      selectedProjectType,
      selectedProjectScale,
      selectedDescription,
      recommendations,
      selectedFragmentSize,
      selectedSurfaceType,
      fragmentRecommendation,
      projectTypes,
      fragmentSizes,
      surfaceTypes,
      availableScales,
      updateProjectScale,
      getRecommendation,
      getFragmentRecommendation
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Inter:wght@400;600&display=swap');
.header {
  padding: 20px;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 100;
  margin-bottom: 1%;
  margin-top:-2%;
}

.back-button {
  padding: 10px 15px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  margin-right:14%;
}

.back-button:hover {
  background-color: #007bff;
  transform: translateY(-2px);
}

.heading1 {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  margin: 0;
  font-size: 2.2em;
  text-align: center;
  flex-grow: 1;
  margin-lef:10px;
}
.site-footer {
  margin-top: auto;
  padding: 20px;
  position: relative;
}

.diy-tools-button {
  position: absolute;
  top:20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.diy-tools-button:hover {
  background-color: #5cacee;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.diy-tools-button i {
  margin-left: 8px;
}

.eps-assistant {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #e6f3ff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
}

.title {
  marign-left:200px;
  font-size: 2.2rem;
  font-weight: 700;
  font-family: 'Patua One', cursive;
  color: #2c3e50;

}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.image-container {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-image {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.arrow-image { 
  width: 50%;
  max-width: 150px;
  align-self: flex-end;
  margin-right: 100px;
  margin-bottom: 20px;
}

.main-content {
  width: 50%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #e6f3ff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  color: #005a8c;
  font-size: 0.9rem;
}

.tabs button.active {
  background-color: #005a8c;
  color: white;
}

.tab-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
}

.select-container {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #005a8c;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #99c2ff;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  color: #005a8c;
}

.description-container {
  background-color: #e6f3ff;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  color: #005a8c;
  overflow-y: auto;
}

button {
  padding: 12px;
  background-color: #005a8c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
  align-self: center;
  margin-left: 190px;
}

button:disabled {
  background-color: #99c2ff;
  cursor: not-allowed;
}

.recommendations {
  margin-top: 20px;
  background-color: #e6f3ff;
  border-radius: 4px;
  padding: 20px;
  color: #005a8c;
}

.tool-recommendation {
  margin-bottom: 20px;
}

.recommendations h3, 
.recommendations h4, 
.recommendations h5 {
  margin: 15px 0;
  color: #005a8c;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.recommendations ul, 
.recommendations ol {
  padding-left: 20px;
}

.recommendations li {
  margin-bottom: 8px;
}




@media (max-width: 1024px) {
  .content-wrapper {
    flex-direction: column;
  }
  
  .image-container, 
  .main-content {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>