<template>
  <div class="best-practice-page">
    <div class="header">
      <router-link :to="{ name: 'BestPractices' }" class="back-button">
        &larr; Back
      </router-link>
      <h2 class="title">Best Practices for Handling Cafe Waste </h2>
    </div>

    <div class="best-practices">
      <div v-for="(practice, index) in practices" :key="index" class="practice-section" :class="{ reverse: index % 2 !== 0 }">
        <div class="text-content">
          <h3>{{ practice.title }}</h3>
          <p>{{ practice.description }}</p>
          <router-link v-if="practice.link" :to="practice.link.to" class="facility-link">
            {{ practice.link.text }}
          </router-link>
        </div>
        <div class="image-container">
          <img :src="practice.image" :alt="practice.title" class="practice-image" @click="openModal(practice.image)"/>
        </div>
      </div>
    </div>

    <!-- Modal for zoomed image -->
    <div v-if="modalOpen" class="modal" @click="closeModal">
      <img :src="modalImage" alt="Zoomed image" class="modal-image" @click.stop>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CafePost',
  data() {
    return {
      modalOpen: false,
      modalImage: '',
      practices: [
        {
          title: "1. Offer Reusable Cups",
          description: "Encourage customers to bring their own reusable cups or provide reusable alternatives for takeaway drinks. Offer incentives like discounts for those who bring their own cups.",
          image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCLsgtYIFHCJdGMmzVFkyNoOyyZkOXxwbVFg&s"
        },
        {
          title: "2. Switch to Eco-Friendly Alternatives",
          description: "Replace polystyrene cups with biodegradable or compostable materials, such as paper or plant-based plastics. Ensure that the alternatives are both eco-friendly and cost-effective.",
          image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIcATKHh6JbOb5Lh1ELm-mEwQxLCFOtK3EVw&s"
        },
        {
          title: "3. Educate Customers on Proper Disposal",
          description: "Place clear signage near waste bins to help customers identify how to dispose of their cups properly. Make sure the waste is separated into recyclable, compostable, and general waste categories.",
          image: "https://preview.redd.it/seeking-artistic-help-for-recycling-guide-posters-deets-in-v0-t8h916hl8nja1.jpg?width=612&format=pjpg&auto=webp&s=6052338c31e511269062e40266ea7f2f81056e3a"
        },
        {
          title: "4. Connect to Recycling Centers",
          description: "Encourage customers and cafe owners to connect with nearby recycling facilities to dispose of plastic and polystyrene waste responsibly. Click below to find a facility near you.",
          image: "https://images.squarespace-cdn.com/content/v1/60a1bd06fb417b609db9b986/a16e8f29-0076-4751-8ef7-4a750d34f530/Poster.jpg?format=500w",
          link: {
            to: "/facilityList",
            text: "Find a Facility Near You"
          }
        }
      ]
    };
  },
  methods: {
    openModal(image) {
      this.modalImage = image;
      this.modalOpen = true;
      document.body.style.overflow = 'hidden';
    },
    closeModal() {
      this.modalOpen = false;
      document.body.style.overflow = 'auto';
    }
  }
};
</script>

<style scoped>
.best-practice-page {
  padding: 20px;
  background-color: #f8f9fa;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.back-button {
  padding: 10px 15px;
  color: #fff;
  background-color: #87CEEB;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 20px;
  font-family: 'Inter', sans-serif;
}

.back-button:hover {
  background-color: #007BFF;
}

.title {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  font-size: 2.5em;
  margin: 0;
  padding-left: 100px;
  flex: 1;
  text-align: center;
}

.best-practices {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.practice-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.practice-section.reverse {
  flex-direction: row-reverse;
}

.text-content {
  flex: 1;
  max-width: 50%;
}

.practice-section h3 {
  font-family: 'Patua One', sans-serif;
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #333;
}

.practice-section p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
  font-family: 'Inter', sans-serif;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  overflow: hidden;
}

.practice-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.practice-image:hover {
  transform: scale(1.05);
}

.facility-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #87CEEB;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.facility-link:hover {
  background-color: #007BFF;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .back-button {
    margin-bottom: 10px;
    
  }

  .title {
    font-size: 1.8em;
    padding-left: 0;
  }

  .best-practices {
    gap: 40px;
  }

  .practice-section, .practice-section.reverse {
    flex-direction: column;
    gap: 20px;
  }

  .text-content {
    max-width: 100%;
  }

  .practice-section h3 {
    font-size: 1.5em;
  }

  .practice-section p {
    font-size: 1em;
  }

  .image-container {
    order: -1;
    width: 100%;
    height: 250px;
  }
}
</style>