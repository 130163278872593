<template>
  <div ref="section2" class="content-section2 impact-content">
    <h1 style ="font-family: 'Patua One', sans-serif; font-size: 35px; text-align: left; margin-top: 20px;">Polystyrene pollution in river is dangerous....</h1>
    <div class="impact-part-1">
      <div class="impact-item">
        <div class="text-left">
          <p>It can cause blockages and chemical exposure in marine animals, threatening their health and ecosystem stability.</p>
        </div>
        <div class="image-right">
          <img src="@/assets/image6.png" alt="Polystyrene Impact" />
        </div>
      </div>
    </div>
    <div class="impact-part-2 animated">
      <p class="animated-text">Yarra River, a major waterway in Melbourne, supports a rich marine ecosystem but faces threats from polystyrene pollution. Click on  red points to view endangered species!</p>
      <div class="map-container">
        <img src="@/assets/YarraCatchment.jpg" alt="Yarra River Map" class="map-image"/>
        <div 
          class="map-point" 
          v-for="(point, index) in points" 
          :key="index" 
          :style="{ 
            top: point.style.top, 
            left: point.style.left,
            width: pointSize + 'px',
            height: pointSize + 'px'
          }"
          @click="showFishPopup(point)">
        </div>
        <div v-if="activeFish" class="popup-box" :style="popupPosition">
          <div class="popup-content">
            <img :src="activeFish.image" alt="Fish Image" />
            <p>{{ activeFish.description }}</p>
            <ul>
              <li v-for="bullet in activeFish.bulletPoints" :key="bullet">{{ bullet }}</li>
            </ul>
            <button class="close-btn" @click="closePopup">Close</button>
          </div>
        </div>
      </div>
    </div>
    <slot name="navigation"></slot>
  </div>
</template>

<script>
import image9 from "@/assets/image9.png";
import image10 from "@/assets/image10.png";
import image11 from "@/assets/image11.png";
import image12 from "@/assets/image12.png";

export default {
  name: 'ImpactSection',
  data() {
    return {
      points: [
        { id: 1, style: { top: '56%', left: '29%' }, fish: { image: image9, description: "Platypus", bulletPoints: ["Platypus became threatened species in 2020.", "8 of 15 Yarra areas lack platypus habitat.", "Urbanisation, climate, and pollution threaten platypus."] }},
        { id: 2, style: { top: '52%', left: '54%' }, fish: { image: image10, description: "Australian Grayling", bulletPoints: ["Critically endangered species.", "Low abundance, no recruitment.", "Lowest fish rates yearly."] }},
        { id: 3, style: { top: '43%', left: '78%' }, fish: { image: image11, description: "Macquarie Perch", bulletPoints: ["Threatened in Victoria and nationally.", "Long-term decline, fragmented populations.", "Low adult abundance detected yearly."] }},
        { id: 4, style: { top: '56%', left: '90%' }, fish: { image: image12, description: "Murray Cod", bulletPoints: ["Threatened in Victoria and nationally", "Small, self-sustaining population", "Low adults, possible angling pressure"] }},
      ],
      activeFish: null,
      popupPosition: { top: '80%', left: '100%' },
      pointSize: 16, 
    };
  },
  methods: {
    showFishPopup(point) {
      this.activeFish = point.fish;
      this.popupPosition = {
        top: `calc(${point.style.top} - 40px)`,
        left: `calc(${point.style.left} - 120px)`
      };
    },
    closePopup() {
      this.activeFish = null;
      this.popupPosition = { top: '80%', left: '100%' };
    },
  },
}
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: auto;
}
.impact-content {
  width: 100%;  /* Make the content section span the full width of the screen */
  margin: 0;    /* Reset margin to avoid unnecessary spacing */
  padding: 0;   /* Reset padding to make it consistent */
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
}

.content-section {
  padding: 20px; 
}
.map-image {
  width: 100%;
  height: auto;
}
.map-point {
  position: absolute;
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer; 
   transition: transform 0.2s ease;
}

.map-point:hover {
  transform: scale(1.2);
}

.popup-box { 
    position: absolute; 
    width: 300px; 
    background: white; 
    border: 1px solid #ccc; 
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5); 
    padding: 10px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; }

.popup-content img { 
    width: 100px; 
    height: auto; }

.popup-content p { 
    margin-top: 10px;
    text-align: center; }

.close-btn { 
    margin-top: 10px; 
    padding: 5px 10px; 
    border: none; 
    background-color: #f44336; 
    color: white; 
    border-radius: 5px; 
    cursor: pointer; } 
</style>
