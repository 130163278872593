<template>
  <div class="content-section2 solution-content">
    <h1 class="section-title">Solutions to Protect Marine Ecosystem</h1>
    <p class="section-subtitle">Explore effective options to make a difference</p>
    
    <div class="solutions-grid">
      <div class="solution-card">
        <h2 class="card-title">1. About Polystyrene</h2>
        <div class="card-content">
          <p>Learn more about polystyrene waste</p>
          <div class="alternatives-images">
            <img src="@/assets/packaging.jpeg" alt="Polystyrene Packaging" />
          </div>
          <div class="facility-link-container">
            <router-link to="/information-hub" class="facility-link">Information Hub</router-link>
          </div>
        </div>
      </div>
      <div class="solution-card">
        <h2 class="card-title">2. Identify Plastics, Go Green</h2>
        <div class="card-content">
          <p>Opt for eco-friendly alternatives</p>
          <div class="alternatives-images">
            <img src="@/assets/biodegradable.jpeg" alt="Biodegradable Alternative" />
          </div>
          <div class="facility-link-container">
            <router-link to="/plastic-image-upload" class="facility-link">Plastic Recognition</router-link>
          </div>
        </div>
      </div>
      <div class="solution-card">
        <h2 class="card-title">3. Recycle</h2>
        <div class="card-content">
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/5hiUiuqvgmE?autoplay=0&loop=1&playlist=5hiUiuqvgmE&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </div>
          <p>Recycle clean, separated polystyrene</p>
          <div class="facility-link-container">
            <router-link to="/facilityList" class="facility-link">Find a Facility</router-link>
          </div>
        </div>
      </div>
      <div class="solution-card">
        <h2 class="card-title">4. Reuse</h2>
        <div class="card-content">
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/1Zts1tXX3Ds?start=36&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </div>
          <p>Repurpose Expanded polystyrene (EPS)</p>
          <div class="facility-link-container">
            <router-link to="/eps-tools" class="facility-link">DIY Tools for EPS</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionSection',
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');

.content-section2 {
  font-family: 'Inter', sans-serif;
  padding: 20px;
  color: #122f37;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-family: 'Patua One', sans-serif;
  font-size: clamp(24px, 3vw, 32px);
  color: #122f37;
  margin: 0 0 10px 0;
  text-align: center;
}

.section-subtitle {
  font-size: clamp(16px, 2vw, 20px);
  text-align: center;
  margin-bottom: 20px;
  color: #122f37;
  font-family: 'Inter', sans-serif;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.solution-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
}

.solution-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
}

.card-title {
  font-size: 18px;
  color: #122f37;
  margin-bottom: 10px;
  text-align: center;
}

.card-content {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-content p {
  text-align: center;
  margin-bottom: 10px;
}

.video-container {
  position: relative;
  width: 50%;
  padding-bottom: 30%;
  height: 0;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 8px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.facility-link-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.facility-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Inter', sans-serif;
}

.facility-link:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.alternatives-images {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.alternatives-images img {
  width: 15vw;
  height: 15vh;
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.alternatives-images img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .solutions-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 22px;
  }

  .section-subtitle {
    font-size: 16px;
  }

  .card-title {
    font-size: 16px;
  }

  .card-content {
    font-size: 12px;
  }

  .facility-link {
    padding: 6px 10px;
    font-size: 12px;
  }
}
</style>