<template>
  <div class="plastic-classification">
    <div class="content">
      <header class="header">
      <router-link :to="{ name: 'EcoSolutions' }" class="back-button">
        &larr; Back 
      </router-link>
      <h1 class="heading1">Plastic Image Classification</h1>
    </header>
      <div class="description-section">
        <p>Upload an image of plastic waste, and our AI model will classify the type of plastic and provide recycling information specific to the Yarra River area.</p>
      </div>
      <div class="sample-images-section semi-transparent-bg">
        <h3>Common plastic images</h3>
        <p>Click on an image to select it for classification:</p>
        <div class="sample-images-grid">
          <div v-for="(image, index) in sampleImages" :key="index" class="sample-image-item" @click="selectSampleImage(image)">
            <img :src="image.src" :alt="image.alt" class="sample-image">
          </div>
        </div>
      </div>
    </div>
      <div class="main-content1" :class="{ 'with-result': result }">
        <div class="upload-section" :class="{ 'expanded': selectedFile || result }">
          <div class="semi-transparent-bg">
            <div 
              class="upload-area" 
              @dragover.prevent
              @drop.prevent="handleDrop"
              :class="{ 'drag-over': isDragging }"
              @dragenter.prevent="isDragging = true"
              @dragleave.prevent="isDragging = false"
            >
              <input type="file" @change="handleFileChange" accept="image/*" ref="fileInput" class="file-input" />
              <div v-if="!selectedFile">
                <i class="fas fa-cloud-upload-alt"></i>
                <p style="font-family: 'Patua One', cursive;"> Drag & Drop your image here or</p>
                <button @click="triggerFileInput" class="upload-btn">Select Image</button>
              </div>
              <div v-else class="preview-section">
                <h3>Image Preview</h3>
                <img :src="imagePreview" alt="Preview" class="preview-image" />
                <div class="preview-actions">
                  <button @click="cancelSelection" class="cancel-btn">Cancel</button>
                  <button @click="uploadImage" class="classify-btn">Classify Image</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="result" class="result-section">
         <div class="result-content semi-transparent-bg">
          <div class="result-content">
            <h3>Classification Result and Recycling Guide around Yarra River</h3>
            <div class="result-card" :class="resultClass">
              <div class="result-type">
                <span class="type-label">Type of Plastic:</span>
                <span class="type-value">{{ result }}</span>
              </div>
              <div class="result-description" v-html="getDescription(result)"></div>
            </div>
            <p class="disclaimer">Note: Our model has an accuracy of approximately 70%. Please double-check results as the model may occasionally misidentify or miss certain plastics. We're continuously improving the model to enhance its accuracy.</p>
          </div>
        </div>
        </div>
      </div>

    <div class="image-recognition-section">
      <img src="@/assets/imgae-recognition.jpg" alt="Image Recognition" class="image-recognition" />
    </div>
    <footer class="site-footer">
     <router-link :to="{ name: 'FacilityList' }" class="diy-tools-button">
      Go to Facility Finding
      <i class="fas fa-arrow-right"></i>
    </router-link>
    </footer>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import ps2 from '@/assets/ps2.jpg';
import ps4 from '@/assets/ps4.jpg';
import PEHD from '@/assets/PEHD.jpg';
import pet from '@/assets/pet.jpg';

export default {
  setup() {
    const selectedFile = ref(null);
    const imagePreview = ref(null);
    const result = ref(null);
    const fileInput = ref(null);
    const isDragging = ref(false);

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

    const sampleImages = ref([
  { src: ps2, alt: 'ps1' },
  { src: ps4, alt: 'ps2' },
  { src: PEHD, alt: 'HDPE Container' },
  { src: pet, alt: 'PET' },
  ]);

    const validateFile = (file) => {
      if (!ALLOWED_TYPES.includes(file.type)) {
        alert('Please select a valid image file (JPEG, PNG, or GIF).');
        return false;
      }
      if (file.size > MAX_FILE_SIZE) {
        alert('File size should not exceed 5MB.');
        return false;
      }
      return true;
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file && validateFile(file)) {
        setSelectedFile(file);
      } else {
        event.target.value = ''; // Reset file input
      }
    };

    const handleDrop = (event) => {
      isDragging.value = false;
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('image/') && validateFile(file)) {
        setSelectedFile(file);
      } else {
        alert('Please drop a valid image file.');
      }
    };

    const setSelectedFile = (file) => {
      selectedFile.value = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        imagePreview.value = e.target.result;
      };
      reader.readAsDataURL(file);
      result.value = null;
    };

    const selectSampleImage = (image) => {
      imagePreview.value = image.src;
      fetch(image.src)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], image.alt, { type: 'image/jpeg' });
          setSelectedFile(file);  
        });
    };

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const cancelSelection = () => {
      selectedFile.value = null;
      imagePreview.value = null;
      result.value = null;
      if (fileInput.value) {
        fileInput.value.value = '';
      }
    };

     const uploadImage = async () => {
      if (!selectedFile.value) {
        alert('Please select an image to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('image', selectedFile.value);

      try {
        const response = await fetch('https://www.backend.policetyrene.me/api/plastic-image-upload/', {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Received data:', data);

        if (data && data.result) {
          result.value = data.result;
        } else {
          throw new Error('Unexpected data format received from the server');
        }

        console.log('Processed result:', result.value);
      } catch (error) {
        console.error('Error uploading the image:', error);
        alert('An error occurred while classifying the image. Please try again.');
      }
    };

    const getDescription = (plasticType) => {
      const descriptions = {
        'PET': `
          <p><strong>Full name:</strong> Polyethylene Terephthalate (PET)</p>
          <p><strong>Common uses:</strong> Beverage bottles, water bottles, food packaging</p>
          <p><strong>Local disposal:</strong> Recyclable in yellow-lid bins. Ensure bottles are clean, dry, and caps (usually PP) are separated for efficient processing.</p>
          <p><strong>River/Ocean impact:</strong> Low if recycled properly. PET can be reused multiple times. If not handled correctly, it may break down into microplastics, polluting the Yarra River and affecting marine life in Port Phillip Bay.</p>
        `,
        'PE-HD': `
          <p><strong>Full name:</strong> High-Density Polyethylene (PE-HD)</p>
          <p><strong>Common uses:</strong> Milk jugs, shampoo bottles, detergent containers</p>
          <p><strong>Local disposal:</strong> Recyclable in yellow-lid bins. Rinse containers and remove labels if possible.</p>
          <p><strong>River/Ocean impact:</strong> Moderate. While recyclable, PE-HD can persist in water bodies for long periods if not properly disposed of, potentially harming aquatic life in the Yarra River and Port Phillip Bay.</p>
        `,
        'PVC': `
          <p><strong>Full name:</strong> Polyvinyl Chloride (PVC)</p>
          <p><strong>Common uses:</strong> Pipes, window frames, flooring, cables</p>
          <p><strong>Local disposal:</strong> Not typically recyclable in household bins. Check with local recycling centers for specialized PVC recycling programs.</p>
          <p><strong>River/Ocean impact:</strong> High. PVC can release harmful chemicals when degrading, posing significant risks to aquatic ecosystems in the Yarra River and Port Phillip Bay.</p>
        `,
        'PE-LD': `
          <p><strong>Full name:</strong> Low-Density Polyethylene (PE-LD)</p>
          <p><strong>Common uses:</strong> Plastic bags, squeezable bottles, some clothing and furniture</p>
          <p><strong>Local disposal:</strong> Often recyclable, but check local guidelines. Many supermarkets offer plastic bag recycling programs.</p>
          <p><strong>River/Ocean impact:</strong> Moderate to high. PE-LD can break down into microplastics, potentially harming marine life in the Yarra River and Port Phillip Bay.</p>
        `,
        'PP': `
          <p><strong>Full name:</strong> Polypropylene (PP)</p>
          <p><strong>Common uses:</strong> Food containers, straws, bottle caps, car parts</p>
          <p><strong>Local disposal:</strong> Recyclable in yellow-lid bins. Ensure items are clean and dry to avoid contaminating other recyclables.</p>
          <p><strong>River/Ocean impact:</strong> Moderate. PP is durable and can persist in water for long periods. It may be mistaken for food by fish and other aquatic life in the Yarra River, affecting their health.</p>
        `,
        'PS': `
          <p><strong>Full name:</strong> Polystyrene (PS)</p>
          <p><strong>Common uses:</strong> Foam packaging, disposable cups and plates, CD cases</p>
          <p><strong>Local disposal:</strong> Take clean, rigid PS and expanded polystyrene to recycling centers. If contaminated, discard in general waste bins.</p>
          <p><strong>River/Ocean impact:</strong> Very high. PS easily breaks into tiny particles, forming microplastics that severely pollute the Yarra River and its watershed. This affects the aquatic ecosystem and causes long-term harm to marine life in Port Phillip Bay.</p>
        `,
        'Other': `
          <p><strong>Full name:</strong> Other types of plastic or mixed materials</p>
          <p><strong>Common uses:</strong> Various applications including multi-layer packaging, certain types of food containers, etc.</p>
          <p><strong>Local disposal:</strong> Check with local authorities for specific disposal instructions. Often not recyclable in standard household recycling.</p>
          <p><strong>River/Ocean impact:</strong> Potentially high, depending on the specific material. These plastics may not be easily recyclable and could contribute to pollution in the Yarra River and Port Phillip Bay.</p>
        `
      };
      return descriptions[plasticType] || 'Description not available.';
    };

    const resultClass = computed(() => {
      if (result.value) {
        return result.value;
      }
      return '';
    });

    return {
      selectedFile,
      imagePreview,
      result,
      fileInput,
      isDragging,
      handleFileChange,
      handleDrop,
      triggerFileInput,
      cancelSelection,
      uploadImage,
      getDescription,
      resultClass,
      validateFile,
      sampleImages,
      selectSampleImage,
      setSelectedFile,
    };
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.site-footer {
  margin-top: auto;
  padding: 20px;
  position: relative;
}

.diy-tools-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.diy-tools-button:hover {
  background-color: #5cacee;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.diy-tools-button i {
  margin-left: 8px;
}
.header {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 100;
}

.back-button {
  padding: 10px 15px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
}

.back-button:hover {
  background-color: #007bff;
  transform: translateY(-2px);
}

.heading1 {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  margin-left: 25%;
  font-size: 2.2em;
  
  flex-grow: 1;
}


.plastic-classification {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.description-section {
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Inter', sans-serif;
  color: #333;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.description-section p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.main-content1 {
  display: flex;
  justify-content: center;
  gap: 20px; /* 减少间距 */
  align-items: stretch; 
  z-index: 999;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-content1.with-result {
  justify-content: center; 
}

h2 {
  font-family: 'Patua One', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #2c3e50;
}

h3 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
}

.semi-transparent-bg {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.upload-section, .result-section {
  flex: 1;
  width: 100%;
  max-width: 600px; 
  min-height: 500px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.upload-section {
  height: 300px;
}

.upload-section.expanded {
  height: auto;
  
}

.upload-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;   
  border: 3px dashed #ccc;
  border-radius: 15px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: rgba(249, 249, 249, 0.5);
  overflow: hidden;
}

.preview-section {
  margin-top: 20px;
}

.upload-area.drag-over {
  background-color: #e9e9e9;
  border-color: #2196F3;
  transform: scale(1.02); 
}

.upload-area i {
  font-size: 60px; 
  color: #888;
  margin-bottom: 20px; 
}

.upload-area p {
  font-size: 18px; 
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 15px;
}

.file-input {
  display: none;
}

.upload-btn, .classify-btn, .cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  margin: 5px;
  font-size: 16px;
}

.upload-btn, .classify-btn {
  background-color: #87CEEB;
  color: white;
  font-family: 'Inter', sans-serif;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.preview-section h3 {
  margin-bottom: 15px;
  color: #333;
}

.preview-actions {
  margin-top: 15px;
}

.result-content {
  height: 100%;
}

.result-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 5px 15px rgba(0,0,0,0.08);
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
}

.result-type {
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.type-label {
  color: #3498db;
  margin-right: 10px;
}

.type-value {
  color: #2c3e50;
  background-color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
}

.result-description {
  font-size: 1em;
  color: #34495e;
  line-height: 1.6;
  text-align: left;
}

.result-description p {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  font-family: 'Inter', sans-serif;
}

.result-description p:first-child {
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  color: #3498db;
  margin-bottom: 20px;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 10px;
}

.result-description strong {
  color: #2980b9;
  font-weight: 600;
}

.image-recognition-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image-recognition {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.disclaimer {
  font-size: 0.8em;
  color: #e74c3c;
  font-style: italic;
  text-align: center;
  margin-top: 15px;
  padding: 10px;
  background-color: #fdf2f0;
  border-radius: 5px;
  border: 1px solid #fadbd8;
  font-family: 'Inter', sans-serif;
}

.result-card[class*="PET - polyethylene terephthalate"] { background-color: #E8F5E9; }
.result-card[class*="PE-HD - high-density polyethylene"] { background-color: #E3F2FD; }
.result-card[class*="PP - polypropylene"] { background-color: #FFFDE7; }
.result-card[class*="PS - polystyrene"] { background-color: #E1F5FE; }
.result-card[class*="Other"] { background-color: #FAFAFA; }
.result-card[class*="Others"] { background-color: #FAFAFA; }

.sample-images-section {
  text-align: center;
  font-family: 'Inter', sans-serif;

}

.sample-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.sample-image-item {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.sample-image-item:hover {
  transform: scale(1.05);
}

.sample-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .main-content1
   {
    flex-direction: column;
    align-items: center;
  }

  .upload-section, .result-section {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .sample-images-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>