<template>
  <div ref="section2" class="content-section2 impact-content">
    <div id="map1-container">
    <header class="header">
      <h2 class="simulation-title">Polystyrene Waste Flow Simulation</h2>
    </header>
    <div class="simulation-container">
      <div class="map-container">
        <l-map
          ref="map"
          :zoom="11"
          :center="[latitude, longitude]"
          @click="onMapClick"
        >
          <l-tile-layer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          <l-geo-json
            v-if="riverLinesData"
            ref="riverLinesLayer"
            :geojson="riverLinesData"
            :options="riverOptions"
            :key="riverLayerKey"
          />
          <l-geo-json
            v-if="graphNodesData"
            ref="graphNodesLayer"
            :geojson="graphNodesData"
            :options="nodeOptions"
            :key="graphLayerKey"
            @add="onLayerAdd"
          />
          <l-marker
            v-if="selectedLocation"
            :lat-lng="selectedLocation"
            :icon="userLocationIcon"
          ></l-marker>
          <l-circle-marker
            v-if="nearestNode"
            :lat-lng="[
              nearestNode.geometry.coordinates[1],
              nearestNode.geometry.coordinates[0],
            ]"
            :radius="10"
            color="purple"
          ></l-circle-marker>
        </l-map>
        <el-alert
          class="map-tip"
          title="Tip: Purple hollow circle indicates the entry point"
          type="info"
          :closable="false"
          show-icon
        />
      </div>
      <div class="info-container">
        <div class="info-card">
          <div class="info-icon">
            <i class="fas fa-info-circle"></i>
          </div>
          <div class="info-content">
            <p>Polystyrene waste is lightweight and easily carried by wind, making it more likely to enter waterways through drains and rivers.</p>
            <p>This simulation shows how waste thrown near the Yarra River might enter the waterways, move around, and where it could ultimately end up.</p>
          </div>
        </div>
        <div class="instruction-card">
          <div class="instruction-icon">
            <i class="fas fa-mouse-pointer"></i>
          </div>
          <div class="instruction-content">
            <p><strong>Click on the map to choose a littering location, then hit 'Start Simulation' to begin!</strong></p>
          </div>
        </div>
        <button class="start-simulation-btn" @click="startSimulation">
          <i class="fas fa-play"></i> Start Simulation
        </button>
      </div>
    </div>
  </div>
</div>  
</template>

<script>
import { ElAlert } from 'element-plus'
import { defineComponent } from 'vue';
import {
  LMap,
  LTileLayer,
  LGeoJson,
  LMarker,
  LCircleMarker,
} from '@vue-leaflet/vue-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';

export default defineComponent({
  name: 'SimulationView',
  components: {
    ElAlert,
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
    LCircleMarker,
  },
  data() {
    return {
      highlightedRiverIds: new Set(),
      animationComplete: false,
      latitude: -37.7870,
      longitude: 145.1234,
      riverLinesData: null,
      graphNodesData: null,
      riverLayers: {},
      highlightedRivers: new Set(),
      riverOptions: {
        onEachFeature: (feature, layer) => {
          const objectId = String(feature.properties.objectid);
          this.riverLayers[objectId] = layer;
        },
        // eslint-disable-next-line no-unused-vars
        style: (feature) => {
          return {
            color: '#0077be',
            weight: 2,
            opacity: 0.6,
          };
        },
      },
      nodeOptions: {
        pointToLayer: (feature, latlng) => {
          let markerColor = 'gray';
          let markerOpacity = 0.4; 
          let markerRadius = 0.5; 

          if (feature.properties.isHighlighted) {
            return L.marker(latlng, {
              icon: L.icon({
                iconUrl: 'https://img.icons8.com/?size=100&id=fWixaB5I7h8U&format=png&color=000000',
                iconSize: [32, 32],
                iconAnchor: [16, 16]
              })
            });
          } else if (feature.properties.isPassed) {
            markerOpacity = 1.0; 
            if (feature.properties.waste_trap) {
              markerColor = 'red';
              markerRadius = 4; 
            } else {
              markerColor = 'green';
              markerRadius = 1;
            }
          }
          return L.circleMarker(latlng, {
            radius: markerRadius,
            color: markerColor,
            opacity: markerOpacity,
          });
        },
        onEachFeature: (feature, layer) => {
          if (feature.properties) {
            if (feature.properties.waste_trap && feature.properties.isPassed) {
              layer.bindTooltip('Suggested location for waste trap', {
                permanent: false,
                direction: 'top',
                opacity: 0.7,
                className: 'custom-tooltip'
              });
            }
          }
        },
      },
      selectedLocation: null,
      nearestNode: null,
      userLocationIcon: L.icon({
        iconUrl: 'https://img.icons8.com/?size=100&id=4HdynxB1QuEx&format=png&color=000000',
        iconSize: [41, 41],
        iconAnchor: [12, 41],
      }),
      adjacencyList: {},
      incomingEdges: {},
      graphLayerKey: 0,
      riverLayerKey: 0,
      edgeToRiverLineMap: {},
      totalDistanceTravelled: 0,
      intersectionCount: 0,
      probabilityLevel: '',
      riskWaterSupplyNames: [],
    };
  },
  methods: {
    async loadGeoJSON() {
      try {
        // Load the river lines data from Django REST framework
        const riverResponse = await axios.get('https://www.backend.policetyrene.me/api/river-lines/');
        console.log('River response:', riverResponse);

        if (!riverResponse.data || !Array.isArray(riverResponse.data)) {
          console.error('Invalid river lines data format:', riverResponse.data);
          throw new Error('Invalid river lines data format');
        }
        
        // Convert the array of river line objects to GeoJSON format
        this.riverLinesData = {
          type: 'FeatureCollection',
          features: riverResponse.data.map(river => ({
            type: 'Feature',
            properties: {
              objectid: river.objectid,
              compkey: river.compkey,
              comptype: river.comptype,
              unitid: river.unitid,
              unitid2: river.unitid2,
              parallel_line_nbr: river.parallel_line_nbr,
              unittype: river.unittype,
              asset_name: river.asset_name,
              asset_id: river.asset_id,
              subarea: river.subarea,
              material: river.material,
              upstream_il: river.upstream_il,
              downstream_il: river.downstream_il,
              length: river.length,
              base_width: river.base_width,
              channel_width: river.channel_width,
              drainage_category: river.drainage_category,
              municipal_code_1: river.municipal_code_1,
              municipal_code_2: river.municipal_code_2,
              municipal_code_3: river.municipal_code_3,
              sub_catchment_nbr: river.sub_catchment_nbr,
              streams_id: river.streams_id,
              isc_reach: river.isc_reach,
              stream_order: river.stream_order,
              date_of_construction: river.date_of_construction,
              asset_owner: river.asset_owner,
              epms_sec_no: river.epms_sec_no,
              asconst_plan_no: river.asconst_plan_no,
              method_of_capture: river.method_of_capture,
              source: river.source,
              date_captured: river.date_captured,
              date_last_updated: river.date_last_updated,
              service_status: river.service_status,
              service_status_chg_date: river.service_status_chg_date,
              service_status_plan_no: river.service_status_plan_no,
              comments: river.comments,
              waterways_index_right: river.waterways_index_right,
              year: river.year,
              ste_code: river.ste_code,
              ste_name: river.ste_name,
              lga_code: river.lga_code,
              lga_name: river.lga_name,
              lga_area_code: river.lga_area_code,
              lga_type: river.lga_type,
              lga_name_long: river.lga_name_long,
            },
            geometry: river.geo_json
          }))
        };

        // Set feature.id to feature.properties.objectid
        this.riverLinesData.features.forEach((feature) => {
          if (feature && feature.properties) {
            feature.id = String(feature.properties.objectid);
          } else {
            console.warn('Invalid feature format:', feature);
          }
        });

        // Load graph data from the backend (nodes and edges)
        const graphResponse = await axios.get('https://www.backend.policetyrene.me/api/graph-data/');
        console.log('Graph response:', graphResponse);

        if (!graphResponse.data || !graphResponse.data.nodes || !graphResponse.data.edges) {
          console.error('Invalid graph data format:', graphResponse.data);
          throw new Error('Invalid graph data format');
        }
        
        const { nodes, edges } = graphResponse.data;

        // Build the adjacency list and map edges to river lines
        this.adjacencyList = {};
        this.incomingEdges = {};
        this.edgeToRiverLineMap = {};
        
        if (Array.isArray(edges)) {
          edges.forEach((edge) => {
            if (Array.isArray(edge) && edge.length >= 3) {
              const [source, target, riverLineId] = edge;
              const sourceId = String(source);
              const targetId = String(target);

              if (!this.adjacencyList[sourceId]) {
                this.adjacencyList[sourceId] = [];
              }
              this.adjacencyList[sourceId].push({
                targetId: targetId,
                riverLineId: String(riverLineId),
              });

              if (!this.incomingEdges[targetId]) {
                this.incomingEdges[targetId] = [];
              }
              this.incomingEdges[targetId].push({
                sourceId: sourceId,
                riverLineId: String(riverLineId),
              });

              const edgeKey = `${sourceId}_${targetId}`;
              this.edgeToRiverLineMap[edgeKey] = String(riverLineId);
            } else {
              console.warn('Invalid edge format:', edge);
            }
          });
        } else {
          console.error('Edges data is not an array');
        }
        
        // Convert nodes from graph data to GeoJSON format
        const features = Object.entries(nodes).map(([nodeId, node]) => {
          if (node && node.pos) {
            return {
              type: 'Feature',
              id: String(nodeId),
              properties: {
                elevation: node.elevation,
                risk_water_supply: node.risk_water_supply || false,
                water_catchment_name: node.water_catchment_name || '',
                waste_trap: node.waste_trap || false,
              },
              geometry: {
                type: 'Point',
                coordinates: node.pos,
              },
            };
          } else {
            console.warn('Invalid node format:', node);
            return null;
          }
        }).filter(feature => feature !== null);

        this.graphNodesData = {
          type: 'FeatureCollection',
          features: features,
        };

      } catch (error) {
        console.error('Failed to load data:', error);
        // You might want to set an error state here or show a message to the user
      }
    },
    onLayerAdd() {
      console.log('GeoJSON layer added to the map.');
    },
    onFeatureClick(event) {
      console.log('Feature clicked:', event.layer.feature.properties);
    },
    onMapClick(event) {
      this.selectedLocation = event.latlng;
      this.findNearestNode();
      this.updateEntryPoint();
      this.resetSimulationDisplay(); 
    },
    calculateDistance(coord1, coord2) {
      const lat1 = coord1[1];
      const lon1 = coord1[0];
      const lat2 = coord2[1];
      const lon2 = coord2[0];
      const R = 6371;
      const φ1 = (lat1 * Math.PI) / 180;
      const φ2 = (lat2 * Math.PI) / 180;
      const Δφ = ((lat2 - lat1) * Math.PI) / 180;
      const Δλ = ((lon2 - lon1) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) *
          Math.cos(φ2) *
          Math.sin(Δλ / 2) *
          Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c;
      return distance;
    },
    findNearestNode() {
      if (!this.selectedLocation || !this.graphNodesData) {
        return;
      }

      let nearestNode = null;
      let minDistance = Infinity;
      this.graphNodesData.features.forEach((feature) => {
        const nodeCoords = feature.geometry.coordinates;
        const distance = this.calculateDistance(
          [this.selectedLocation.lng, this.selectedLocation.lat],
          nodeCoords
        );
        if (distance < minDistance) {
          minDistance = distance;
          nearestNode = feature;
        }
      });
      this.nearestNode = nearestNode;
      console.log('Nearest Node found:', this.nearestNode);
    },
    updateEntryPoint() {
      this.resetNodeStyles();

      if (this.nearestNode) {
        const layer = this.$refs.graphNodesLayer.leafletObject.getLayer(this.nearestNode.id);
        if (layer) {
          layer.setStyle({
            color: 'purple',
            fillColor: 'transparent',
            fillOpacity: 0,
            weight: 3,
            radius: 10
          });
          layer.unbindTooltip();
        }
      }
    },
    resetNodeStyles() {
      if (this.graphNodesData) {
        this.graphNodesData.features.forEach((feature) => {
          feature.properties.isHighlighted = false;
          feature.properties.isPassed = false;
          const layer = this.$refs.graphNodesLayer.leafletObject.getLayer(feature.id);
          if (layer) {
            layer.setStyle({
              color: 'gray',
              fillColor: 'gray',
              fillOpacity: 0.4,
              weight: 1,
              radius: 0.5
            });
            layer.unbindTooltip();
          }
        });
      }
    },
    startSimulation() {
      if (!this.selectedLocation) {
        alert('Please select a location on the map.');
        return;
      }
      if (!this.nearestNode) {
        this.findNearestNode();
      }
      if (this.nearestNode) {
        this.resetSimulation();
        this.updateEntryPoint(); 
        this.runSimulation();
      } else {
        alert('No nearest node found.');
      }
    },
    async runSimulation() {
      if (!this.nearestNode) {
        alert('Simulation cannot start without a starting node.');
        return;
      }

      const sequence = [];
      const passedEdges = new Set();
      const visitedNodes = new Set();
      const nodeCoordinates = [];
      let intersectionCount = 0;
      const riskWaterSupplyNamesSet = new Set();

      let currentNodeId = String(this.nearestNode.id);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (visitedNodes.has(currentNodeId)) {
          break;
        }
        visitedNodes.add(currentNodeId);

        const nodeFeature = this.graphNodesData.features.find(
          (feature) => String(feature.id) === currentNodeId
        );
        if (!nodeFeature) {
          console.error('Node feature not found for nodeId:', currentNodeId);
          break;
        }

        nodeFeature.properties.isPassed = true;
        sequence.push(currentNodeId);
        nodeCoordinates.push(nodeFeature.geometry.coordinates);

        if (nodeFeature.properties.risk_water_supply) {
          riskWaterSupplyNamesSet.add(nodeFeature.properties.water_catchment_name);
        }

        const downstreamEdges = this.adjacencyList[currentNodeId] || [];

        if (downstreamEdges.length === 0) {
          break;
        }

        const incomingEdges = this.incomingEdges[currentNodeId] || [];

        if (incomingEdges.length >= 2) {
          intersectionCount += 1;
        }

        let nextEdge = null;
        let minElevation = Infinity;

        if (currentNodeId === '146') {
          nextEdge = downstreamEdges.find(edge => edge.targetId === '160');
        }

        if (!nextEdge) {
          downstreamEdges.forEach((edge) => {
            const targetFeature = this.graphNodesData.features.find(
              (feature) => String(feature.id) === edge.targetId
            );
            if (targetFeature && targetFeature.properties.elevation < minElevation) {
              minElevation = targetFeature.properties.elevation;
              nextEdge = edge;
            }
          });
        }

        if (!nextEdge) {
          break;
        }

        const edgeKey = `${currentNodeId}_${nextEdge.targetId}`;
        passedEdges.add(edgeKey);

        currentNodeId = nextEdge.targetId;
      }

      this.highlightRiverLines(passedEdges);

      this.totalDistanceTravelled = 0;
      for (let i = 1; i < nodeCoordinates.length; i++) {
        const coord1 = nodeCoordinates[i - 1];
        const coord2 = nodeCoordinates[i];
        this.totalDistanceTravelled += this.calculateDistance(coord1, coord2);
      }

      this.intersectionCount = intersectionCount;

      if (intersectionCount < 7) {
        this.probabilityLevel = 'High';
      } else if (intersectionCount <= 12) {
        this.probabilityLevel = 'Medium';
      } else {
        this.probabilityLevel = 'Low';
      }

      this.riskWaterSupplyNames = Array.from(riskWaterSupplyNamesSet);

      await this.animateWasteFlow(sequence);
      this.updateRiverStyles();

      this.showSimulationSummary();
      
    },
    async animateWasteFlow(sequence) {
  for (const nodeId of sequence) {
    const nodeFeature = this.graphNodesData.features.find(
      (feature) => String(feature.id) === nodeId
    );
    if (nodeFeature) {
      nodeFeature.properties.isHighlighted = true;
      this.refreshGraphLayer();
      await this.sleep(500);
      nodeFeature.properties.isHighlighted = false;
    }
  }
  this.animationComplete = true;
  this.refreshLayers();
},
    highlightRiverLines(passedEdges) {
  if (!this.riverLinesData) return;

  this.highlightedRiverIds.clear();

  passedEdges.forEach((edgeKey) => {
    const riverLineId = this.edgeToRiverLineMap[edgeKey];

    if (riverLineId) {
      this.highlightedRiverIds.add(riverLineId);
    } else {
      console.error(`Could not find river line for edgeKey: ${edgeKey}`);
    }
  });
  
  this.updateRiverStyles();
},
  updateRiverStyles() {
  Object.entries(this.riverLayers).forEach(([riverLineId, layer]) => {
    if (this.highlightedRiverIds.has(riverLineId)) {
      layer.setStyle({
        color: 'purple',
        weight: 4,
        opacity: 0.8,
      });
    } else {
      layer.setStyle({
        color: '#0077be',
        weight: 2,
        opacity: 0.6,
      });
    }
  });
},

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  resetSimulation() {
    this.graphNodesData.features.forEach((feature) => {
      feature.properties.isHighlighted = false;
      feature.properties.isPassed = false;
      const layer = this.$refs.graphNodesLayer.leafletObject.getLayer(feature.id);
      if (layer) {
        layer.setStyle({
          color: 'gray',
          fillColor: 'gray',
          fillOpacity: 0.4,
          weight: 1,
          radius: 0.5
        });
        layer.unbindTooltip();
      }
    });

    this.totalDistanceTravelled = 0;
    this.intersectionCount = 0;
    this.probabilityLevel = '';
    this.riskWaterSupplyNames = [];
    this.animationComplete = false;
    this.highlightedRiverIds.clear();
    this.updateRiverStyles();
  },
  resetAll() {
    this.resetSimulation();
    this.highlightedRivers.clear();
    this.refreshLayers();
  },
  refreshGraphLayer() {
    this.graphLayerKey += 1;
  },
  refreshRiverLayer() {
  this.riverLayerKey += 1;
  this.$nextTick(() => {
    this.updateRiverStyles();
  });
},
  refreshLayers() {
    this.refreshGraphLayer();
    this.refreshRiverLayer();
  },
    showSimulationSummary() {
  const summaryContent = `
    <div class="summary-popup">
      <h2 class="summary-title">Simulation Summary</h2>
      <div class="summary-grid">
        <div class="summary-item">
          <span class="summary-label">Distance:</span>
          <span class="summary-value">${this.totalDistanceTravelled.toFixed(2)} km</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Intersections:</span>
          <span class="summary-value">${this.intersectionCount}</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Likely End Up in Ocean:</span>
          <span class="summary-value ${this.probabilityLevel.toLowerCase()}">${this.probabilityLevel}</span>
        </div>
      </div>
      ${this.riskWaterSupplyNames.length > 0 ? `
        <div class="summary-risk">
          <h3>Potential Pollution Risk:</h3>
          <p>Possible affected water supply areas:</p>
          <ul>
            ${this.riskWaterSupplyNames.map(name => `<li>${name}</li>`).join('')}
          </ul>
        </div>
      ` : ''}
    </div>
  `;

  L.popup({
    maxWidth: 300,
    className: 'custom-popup summary-popup'
  })
    .setLatLng(this.selectedLocation)
    .setContent(summaryContent)
    .openOn(this.$refs.map.leafletObject);
  },
  resetSimulationDisplay() {
    this.highlightedRiverIds.clear();
    this.updateRiverStyles();
    this.resetNodeStyles();
    this.refreshLayers();
  },
},
  mounted() {
    this.loadGeoJSON();
  },
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Inter:wght@400;600;700&display=swap');

.content-section2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.map-tip {
  position: absolute;
  bottom: 22.5%;
  left: 6.5%;
  right: 10px;
  z-index: 1000;
  opacity: 0.9;
  width: 50%;
  max-width: 600px;
}

#map1-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px); /* Increased left space from 60px to 80px */
  max-width: 1840px; /* Adjusted from 1860px */
  height: 100vh;
  font-family: 'Inter', sans-serif;
  margin: 0 0 0 auto;
  padding-right: 20px; /* Added right padding */
}

.header {
  padding: 20px;
  margin-left:20%;
}

.simulation-title {
  font-family: 'Patua One', cursive;
  font-size: 2.2rem;
  color: #2c3e50;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.simulation-container {
  display: flex;
  flex: 1;
  width: 100%;
  max-height: calc(100vh - 265px);
  border-radius: 10px;
  overflow: hidden;
}

.map-container {
  flex: 7;
  height: calc(100vh - 100px);
}

.info-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
}

.simulation-title {
  font-family: 'Patua One', cursive;
  font-size: 2.2rem;
  color: #2c3e50;
  margin-left: -30%;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}



.info-card, .instruction-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.info-icon, .instruction-icon {
  font-size: 1.5rem;
  color: #3498db;
  margin-right: 15px;
  flex-shrink: 0;
}

.info-content p, .instruction-content p {
  margin: 0 0 10px 0;
  line-height: 1.4;
  color: #34495e;
  font-size: 0.93rem;
  font-weight: bold;
}

.instruction-content p {
  font-weight: 600;
  color: #2c3e50;
}

.start-simulation-btn {
  width: 80%;
  padding: 15px 20px;
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  margin-left:10%;
  margin-top: 10%;

}

.start-simulation-btn:hover {
  background-color: #5cacee;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.start-simulation-btn i {
  margin-right: 8px;
}


/* Adjust the map size */
.l-map {
  height: 90% !important;
  width: 100% !important;
  margin: 0 !important;
}

@media (max-width: 1366px) {
  #map1-container {
    width: 100%;
    padding-right: 0; /* Remove right padding on smaller screens */
  }

  .simulation-container {
    flex-direction: column;
  }

  .map-container, .info-container {
    width: 100%;
  }

  .map-container {
    height: 60vh;
  }

  .info-container {
    height: auto;
    overflow-y: visible;
  }

  .start-simulation-btn {
    width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 768px) {
  .simulation-title {
    font-size: 1.8rem;
  }

  .map-container {
    height: 50vh;
  }

  .info-card, .instruction-card {
    padding: 15px;
  }

  .start-simulation-btn {
    width: 100%;
    margin-left: 0;
  }
}
</style>

<style>
.leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border-radius: 12px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.leaflet-popup-content {
  margin: 12px !important;
  font-family: 'Inter', sans-serif !important;
  max-width: 300px !important;
}

.summary-popup {
  font-family: 'Inter', sans-serif !important;
}

.summary-popup .summary-title {
  font-family: 'Inter', cursive !important;
  font-size: 1.3rem !important;
  color: #2c3e50 !important;
  margin-bottom: 15px !important;
  text-align: center !important;
  border-bottom: 1px solid #e0e0e0 !important;
  padding-bottom: 10px !important;
}

.summary-popup .summary-grid {
  display: grid !important;
  grid-template-columns: 1fr !important;
  gap: 10px !important;
  margin-bottom: 15px !important;
}

.summary-popup .summary-item {
  background-color: #f8f9fa !important;
  padding: 10px !important;
  border-radius: 8px !important;
  text-align: left !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) !important;
}

.summary-popup .summary-label {
  display: inline-block !important;
  font-weight: 600 !important;
  color: #7f8c8d !important;
  margin-right: 5px !important;
  font-size: 0.9rem !important;
}

.summary-popup .summary-value {
  font-size: 1.0rem !important;
  font-weight: 700 !important;
  color: #2c3e50 !important;
}

.summary-popup .summary-value.high { color: #e74c3c !important; }
.summary-popup .summary-value.medium { color: #f39c12 !important; }
.summary-popup .summary-value.low { color: #27ae60 !important; }

.summary-popup .summary-risk {
  background-color: #fff3cd !important;
  border-left: 3px solid #ffc107 !important;
  padding: 10px !important;
  border-radius: 8px !important;
  margin-top: 15px !important;
  font-size: 0.9rem !important;
}

.summary-popup .summary-risk h3 {
  color: #856404 !important;
  margin-bottom: 8px !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.summary-popup .summary-risk p {
  color: #6c757d !important;
  margin-bottom: 8px !important;
  line-height: 1.4 !important;
}

.summary-popup .summary-risk ul {
  padding-left: 20px !important;
  color: #6c757d !important;
  margin-top: 5px !important;
}

.summary-popup .summary-risk li {
  margin-bottom: 4px !important;
  line-height: 1.3 !important;
}

@media (max-width: 768px) {
  .leaflet-popup-content {
    max-width: 250px !important;
  }

  .summary-popup .summary-title {
    font-size: 1.3rem !important;
  }

  .summary-popup .summary-label,
  .summary-popup .summary-value {
    font-size: 0.9rem !important;
  }

  .summary-popup .summary-risk,
  .summary-popup .summary-risk p,
  .summary-popup .summary-risk li {
  font-size: 0.8rem !important;
  }
}

.custom-tooltip {
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  border: 1px solid purple;
  padding: 5px;
  border-radius: 3px;
}
</style>  