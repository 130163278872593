<template>
  <div class="page-container">
    <div class="eco-summary">
      <h1>Polystyrene Pollution Reduction Summary</h1>
      
      <h2>Select your completed eco-friendly activities:</h2>
      <div class="activity-selection">
        <label v-for="activity in activities" :key="activity.id" class="activity-label">
          <input type="checkbox" v-model="activity.selected" @change="updateImpact">
          {{ activity.name }}
        </label>
      </div>

      <h2>Your Environmental Impact:</h2>
      <div v-if="selectedActivities.length > 0" class="impact-list">
        <p v-for="impact in impacts" :key="impact">• {{ impact }}</p>
      </div>
      <p v-else class="no-impact">Please select your completed eco-friendly activities to view the impact.</p>

      <div class="button-group">
        <button @click="openPreviewModal" class="action-btn preview-btn" :disabled="selectedActivities.length === 0">
          Preview Certificate
        </button>
        <button @click="generateCertificate" class="action-btn generate-btn" :disabled="selectedActivities.length === 0">
          Download Certificate
        </button>
      </div>

      <!-- Certificate Preview Modal -->
      <div v-if="showPreviewModal" class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Certificate Preview</h2>
            <button @click="closePreviewModal" class="close-btn">&times;</button>
          </div>
          <div class="modal-body">
            <div class="preview-container" ref="previewContainer"></div>
          </div>
          <div class="modal-footer">
            <button @click="closePreviewModal" class="action-btn close-btn">Close</button>
            <button @click="generateCertificate" class="action-btn download-btn">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import html2pdf from 'html2pdf.js'

// Import background image and logo
import backgroundImage from '@/assets/subassets/backgroundpic.png'
import logoImage from '@/assets/policetyrenelogo.png'

const activities = ref([
  { id: 1, name: 'Recycling Polystyrene', selected: false, impact: 'Reduces landfill waste and marine plastic pollution.' },
  { id: 2, name: 'Reusing Polystyrene', selected: false, impact: 'Extends product life and indirectly reduces marine plastic pollution.' },
  { id: 3, name: 'Using Alternatives', selected: false, impact: 'Decreased reliance on polystyrene, reducing marine plastic pollution.' }
])

const selectedActivities = computed(() => activities.value.filter(a => a.selected))
const impacts = ref([])
const showPreviewModal = ref(false)
const previewContainer = ref(null)

const updateImpact = () => {
  impacts.value = selectedActivities.value.map(a => a.impact)
}

const calculateTotalImpact = () => {
  const totalActivities = selectedActivities.value.length
  return `Your actions have reduced approximately ${totalActivities * 0.5}kg of polystyrene pollution, making a significant contribution to protecting marine ecosystems!`
}

const getCertificateContent = () => `
  <div style="
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
  ">
    <div style="
      width: 1000px;
      height: 600px;
      padding: 40px; 
      border: 2px solid #87CEEB;
      background-image: url('${backgroundImage}');
      background-size: cover;
      color: #333;
      font-family: 'Inter', sans-serif;
      position: relative;
    ">
      <img src="${logoImage}" alt="Website Logo" style="
        position: absolute;
        top: 28px;
        left: 20px;
        width: 60px;
        height: auto;
      ">
      <div style="
        text-align: center;
        margin-top: 20px;
      ">
        <h1 style="color: #000; margin: 0; font-size: 28px; font-family: 'Patua One', cursive;">Environmental Contribution Certificate</h1>
      </div>
      <div style="
        margin-top: 40px;
        text-align: left;
      ">
        <p>Dear Environmental Pioneer,</p>
        <p>Thank you for your valuable contribution to reducing polystyrene pollution!</p>
        <p>You participated in the following eco-friendly activities:</p>
        <ul style="list-style-type: none; padding: 0;">
          ${selectedActivities.value.map(a => `<li>• ${a.name}</li>`).join('')}
        </ul>
        <p>${calculateTotalImpact()}</p>
        <p>Your actions are helping to protect our oceans and marine life. Keep up the great work for a better future!</p>
      </div>
      <div style="
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
      ">
        <p>Issue Date: 28/09/2024</p>
        <p style="font-style: italic;">Policetyrene</p>
      </div>
    </div>
  </div>
`


const openPreviewModal = () => {
  showPreviewModal.value = true
  setTimeout(() => {
    if (previewContainer.value) {
      previewContainer.value.innerHTML = getCertificateContent()
    }
  }, 0)
}

const closePreviewModal = () => {
  showPreviewModal.value = false
}

const generateCertificate = () => {
  const content = getCertificateContent()
  
  const opt = {
    margin: 0,
    filename: 'eco_friendly_certificate.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'px', format: [1100, 700], orientation: 'landscape' }
  }

  html2pdf().set(opt).from(content).save()
}

// Ensure images are loaded before generating preview
onMounted(() => {
  const img = new Image()
  img.src = backgroundImage
  img.onload = () => {
    console.log('Background image loaded')
  }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Inter:wght@400;700&display=swap');

.page-container {
  min-height: 80vh;
  background-image: url('@/assets/subassets/page-background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  padding: 20px;
  box-sizing: border-box;
}

.eco-summary {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: rgba(249, 249, 249, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h1, h2 {
  font-family: 'Patua One', cursive;
  color: #2c3e50;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  text-align: center;
}

h2 {
  font-size: 1.8em;
  border-bottom: 2px solid #87CEEB;
  padding-bottom: 10px;
}

.activity-selection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.activity-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.activity-label:hover {
  background-color: rgba(135, 206, 235, 0.2);
  padding: 5px;
  border-radius: 5px;
}

.impact-list {
  background-color: rgba(135, 206, 235, 0.2);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.no-impact {
  color: #777;
  font-style: italic;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.action-btn {
  background-color: #87CEEB;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Inter', sans-serif;
}

.action-btn:hover:not(:disabled) {
  background-color: #5CACEE;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.action-btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 30px;
  border-radius: 8px;
  width: 80%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.modal-header, .modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  margin: 30px 0;
  max-height: 70vh;
  overflow-y: auto;
}

.preview-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

.preview-container > div {
  transform: scale(0.8);
  transform-origin: top center;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.close-btn {
  background-color: #87CEEB;
}

.close-btn:hover {
  background-color: #5CACEE;
}
</style>