<template>
  <div class="information-hub">
    <h1>Eco Solutions for Handling Polystyrene Waste</h1>
    
    <div class="grid-container">
    <!-- image recognition Section -->
      <div
        class="grid-item best-practice"
        @click="navigateTo('ImageUpload')"
        @mouseover="hoverEffect($event, true)"
        @mouseleave="hoverEffect($event, false)"
      >
        <h3>Plastic Recognition</h3>
      </div>
      <!-- Recycling Section -->
      <div
        class="grid-item news"
        @click="navigateTo('FacilityList')"
        @mouseover="hoverEffect($event, true)"
        @mouseleave="hoverEffect($event, false)"
      >
        <h3>Find Recycling centres</h3>
      </div>

      <!-- DIY Section -->
      <div
        class="grid-item faqs"
        @click="navigateTo('EpsTool')"
        @mouseover="hoverEffect($event, true)"
        @mouseleave="hoverEffect($event, false)"
      >
        <h3>DIY Tools for EPS </h3>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationHub',
  methods: {
    navigateTo(page) {
      this.$router.push({ name: page });
    },
    hoverEffect(event, isHovering) {
      const item = event.currentTarget;
      if (isHovering) {
        item.style.transform = 'scale(1.05)';
        item.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
      } else {
        item.style.transform = 'scale(1)';
        item.style.boxShadow = 'none';
      }
    },
  },
};
</script>

<style scoped>
.information-hub {
  padding: 20px;
  max-width: 100%;
  min-height: 90vh; 
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  background-image: url('@/assets/background1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.information-hub h1 {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  text-align: center;
  margin: 0 0 20px 0;
  padding: 10px;
  font-size: 2.2em;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.grid-item {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.grid-item h3 {
  margin: 0;
  z-index: 1;
  position: relative;
  transition: transform 0.3s;
  font-family: 'Inter', sans-serif;
}

.grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  transition: opacity 0.3s, transform 0.3s;
}

.grid-item.news::before {
  background-image: url('@/assets/subassets/recyclinglocation.png');
}

.grid-item.faqs::before {
  background-image: url('@/assets/diy1.jpg');
}

.grid-item.best-practice::before {
  background-image: url('@/assets/cups.png');
}

.grid-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-item:hover::before {
  opacity: 0.7;
  transform: scale(1.1);
}

.grid-item:hover h3 {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .information-hub h1 {
    font-size: 2em;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
  
  .grid-item {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .information-hub h1 {
    font-size: 1.8em;
  }

  .grid-item {
    font-size: 1em;
  }
}
</style>