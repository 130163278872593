<template>
  <div :style="{ ...backgroundStyle, height: '125vh' }">
  <header class="header">
      <router-link :to="{ name: 'EcoSolutions' }" class="back-button">
        &larr; Back 
      </router-link>
      <h1 class="main-title">Find your nearby waste facilities for polystyrene</h1>
    </header>
      
    <!-- Knowledge Board Section with Image -->
    <div class="knowledge-board" :style="{ backgroundImage: `url(${require('@/assets/image-re.jpg')})` }">
     <div class="overlay"></div>
      <!-- Container for text and image -->
      <div class="content-container">
        <!-- Text Section -->
        <div class="text-section">
          <h2>
            <img src="@/assets/subassets/bulb.png" alt="Light Bulb Icon" class="icon" />
            How to Properly Recycle Polystyrene
          </h2>
          <ul>
            <li>Only clean polystyrene can be recycled. Make sure to clean it thoroughly before placing it in the recycling center.</li>
            <li>Do not mix polystyrene with other plastics, recycle it separately.</li>
            <li>Compress expanded polystyrene to save space.</li>
            <li>If unsure about recycling, contact your local recycling center or find a specialized recycling company.</li>
            <li class="best-practice-link">
              Learn more about recycling polystyrene in our 
              <router-link to="/BestPractices">Best Practices</router-link> section.
            </li>
          </ul>
        </div>
        
      </div>
    </div>

    <!-- Map Controls -->
    <div class="map-controls">
    <div class="input-group">
      <div class="custom-autocomplete-wrapper">
        <el-autocomplete
          v-model="searchQuery"
          :fetch-suggestions="fetchSuggestions"
          placeholder="Enter a location"
          @select="handlePlaceSelect"
          class="custom-autocomplete"
        >
          <template #prefix>
            <i class="el-icon-location"></i>
          </template>
        </el-autocomplete>
      </div>
      <button class="blue-button" @click="getCurrentLocation">Use My Location</button>
      <div class="filters-dropdown" ref="filtersRef">
          <button class="blue-button filter-button" @click.stop="toggleFilters">Filters</button>
          <div v-if="showFilters" class="filters-content">
            <div class="filter-item">
              <label>
                <input type="checkbox" v-model="filters.recycle" />
                Accept Recycle
              </label>
            </div>
            <div class="filter-item">
              <label>
                <input type="checkbox" v-model="filters.disposal" />
                Accept Disposal
              </label>
            </div>
            <div class="filter-item">
              <label>
                <input type="checkbox" v-model="filters.acceptEPS" />
                Accept EPS
              </label>
            </div>
            <div class="filter-item">
              <label>
                <input type="checkbox" v-model="filters.acceptConsumerPolystyrene" />
                Accept Consumer Polystyrene
              </label>
            </div>
            <div class="filter-item">
              <label>Accepted Amount</label>
              <select v-model="filters.acceptedAmount" class="select-input">
                <option value="">Any</option>
                <option value="only household quantities">Only Household Quantities</option>
                <option value="can accept large amount">Can Accept Large Amount</option>
              </select>
            </div>
            <div class="filter-item slider-container">
              <label>Radius: {{ radius }} km</label>
              <input type="range" v-model.number="radius" min="1" max="100" class="slider" />
              <div class="slider-value">{{ radius }} km</div>
            </div>
            <div class="bl" style="display: flex; margin-left:-2%; justify-content: center;">
            <button class="blue-button" @click.stop="applyFilters">Apply Filters</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3: Map and Facility Information -->
    <div class="step-container">
      <div class="map-container">
        <Map :center="mapCenter" :zoom="10" style="width: 100%; height: 500px" @click="closeInfoWindow">
          <Marker
            v-if="userLocation"
            :position="{ lat: userLocation.lat, lng: userLocation.lng }"
            icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png" />
          <Marker
            v-for="facility in filteredFacilities"
            :key="facility.id"
            :position="{ lat: facility.latitude, lng: facility.longitude }"
            :icon="facility.id === selectedFacility?.id ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
            @click="selectFacility(facility)" />
        </Map>
        <!-- Move the info window inside the map -->
        <div v-if="selectedFacility" class="facility-info">
          <h2>{{ selectedFacility.name }}</h2>
          <p><strong>{{ selectedFacility.address }}, {{ selectedFacility.suburb }}, {{ selectedFacility.postcode }}</strong></p>
          <p><strong>Status:</strong> {{ selectedFacility.operational_status }}</p>
          <p><strong>Recycle:</strong> {{ selectedFacility.recycle ? 'Yes' : 'No' }}</p>
          <p><strong>Disposal:</strong> {{ selectedFacility.disposal ? 'Yes' : 'No' }}</p>
          <p>
          <strong>Accept 
            <el-popover
              placement="top-start"
              title="What is EPS?"
              width="250"
              trigger="hover"
              content="Expanded Polystyrene (EPS) is a type of plastic commonly used in packaging."
            >
              <template #reference>
                <a href="javascript:void(0)" class="info-link">EPS</a>
              </template>
            </el-popover>:
          </strong> 
          {{ selectedFacility.accept_EPS ? 'Yes' : 'No' }}
        </p>

        <p>
          <strong>Accept 
            <el-popover
              placement="top-start"
              title="What is Consumer Polystyrene?"
              width="250"
              trigger="hover"
              content="Consumer Polystyrene is polystyrene commonly used in household items, like disposable plates and foam cups."
            >
              <template #reference>
                <a href="javascript:void(0)" class="info-link">Consumer Polystyrene</a>
              </template>
            </el-popover>:
          </strong> 
          {{ selectedFacility.accept_consumer_polystyrene ? 'Yes' : 'No' }}
        </p>

          <p><strong>Accepted Amount:</strong> {{ selectedFacility.accepted_amount }}</p>
          <p><strong>Conditions:</strong> {{ selectedFacility.conditions || 'No information available' }}</p>
        <p v-if="selectedFacility.websites && selectedFacility.websites !== '-'" class="hint-text">
        <img src="https://cdn1.iconfinder.com/data/icons/ui-6/502/informations-1024.png" alt="Info Icon" class="hint-icon" />
        <span> 
          <a :href="selectedFacility.websites" target="_blank">Visit Website</a> for opening hours and contact details.
        </span>
      </p>


          <p v-else>No website available.</p>
          <button class="blue-button" @click="showNavigationModal">Navigate</button>
          <button class="red-button" @click="closeInfoWindow">Close</button>
        </div>
      </div>
    </div>
    <!-- Modal for choosing travel mode -->
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal">
        <h2>Select Travel Mode</h2>
        <select v-model="travelMode" class="travel-mode-select">
          <option value="DRIVING">Driving</option>
          <option value="WALKING">Walking</option>
          <option value="BICYCLING">Bicycling</option>
          <option value="TRANSIT">Transit</option>
        </select>
        <button class="blue-button" @click="getDirections">Get Directions</button>
        <button class="red-button" @click="closeModal">Cancel</button>
      </div>
    </div>
  <footer class = 'site-footer'>
    <router-link :to="{ name: 'EpsTool' }" class="diy-tools-button">
      Go to DIY Tools
      <i class="fas fa-arrow-right"></i>
    </router-link>
    </footer>
  </div>
</template>


<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { Map, Marker} from '@fawmi/vue-google-maps';
import { ElAutocomplete } from 'element-plus';
import { ElPopover } from 'element-plus';

/* global google */

export default {
  components: {
    Map,
    Marker,
    ElPopover,

    ElAutocomplete
  },
  setup() {
    const showFilters = ref(false)
    const filtersRef = ref(null)
    const showModal = ref(false)
    const travelMode = ref('DRIVING')

    const toggleFilters = () => {
      showFilters.value = !showFilters.value
    }

    const closeModal = () => {
      showModal.value = false
    }

    onClickOutside(filtersRef, () => {
      showFilters.value = false
    })

    return {
      showFilters,
      filtersRef,
      toggleFilters,
      showModal,
      travelMode,
      closeModal
    }
  },
  data() {
    return {
      facilities: [],
      filteredFacilities: [],
      selectedFacility: null,
      searchQuery: '',
      mapCenter: { lat: -37.8136, lng: 144.9631 },
      userLocation: null,
      postcode: '',
      filters: {
        recycle: false,
        disposal: false,
        acceptEPS: false,
        acceptConsumerPolystyrene: false,
        acceptedAmount: ''
      },
      inputStyle: {
        style: {
          width: '300px',
          height: '40px',
          borderRadius: '20px',
          border: '2px solid #3498db',
          padding: '0 15px',
          fontSize: '16px'
        }
      },
      radius: 8,
      loadingPostcode: false,
      yarraCenter: { lat: -37.8136, lng: 144.9631 },
      maxRadius: 50000, // 50 km in meters
    };
  },
  computed: {
    displayQuery() {
      if (this.loadingPostcode) {
        return "Fetching postcode...";
      }
      return this.searchQuery || 'Enter a location';
    }
  },
  mounted() {
    this.fetchFacilities();
  },
  methods: {
    fetchSuggestions(queryString, cb) {
    if (!queryString) return cb([]);
    
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: queryString, types: ['geocode'] }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const suggestions = predictions.map(prediction => ({
          value: prediction.description,
          placeId: prediction.place_id
        }));
        cb(suggestions);
      } else {
        cb([]);
      }
    });
  },
  
  handlePlaceSelect(place) {
    if (!place || !place.placeId) return;

    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId: place.placeId }, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const newLocation = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        };
        this.mapCenter = newLocation;
        this.userLocation = newLocation;
        const postcode = result.address_components.find(ac => ac.types.includes('postal_code'))?.long_name || '';
        this.postcode = postcode;
        this.searchQuery = result.formatted_address || postcode;
      } else {
        console.error('Failed to fetch place details:', status);
      }
    });
  },
    fetchFacilities() {
      fetch('https://www.backend.policetyrene.me/api/facilities/')
        .then(response => response.json())
        .then(data => {
          this.facilities = data.map(facility => ({
            id: facility.id,
            name: facility.name,
            address: facility.address,
            suburb: facility.suburb,
            postcode: facility.postcode,
            operational_status: facility.operational_status,
            recycle: facility.recycle,
            disposal: facility.disposal,
            accept_EPS: facility.accept_EPS,
            accept_consumer_polystyrene: facility.accept_consumer_polystyrene,
            accepted_amount: facility.accepted_amount,
            conditions: facility.conditions === '-' ? null : facility.conditions,
            websites: facility.websites || 'No website provided',
            latitude: parseFloat(facility.latitude),
            longitude: parseFloat(facility.longitude)
          }));
          this.filteredFacilities = [...this.facilities];
        })
        .catch(error => console.error('Error fetching facilities:', error));
    },
    filterFacilities() {
      if (!this.userLocation) {
        this.filteredFacilities = this.facilities.filter(facility => 
          (!this.filters.recycle || facility.recycle) &&
          (!this.filters.disposal || facility.disposal) &&
          (!this.filters.acceptEPS || facility.accept_EPS) &&
          (!this.filters.acceptConsumerPolystyrene || facility.accept_consumer_polystyrene) &&
          (this.filters.acceptedAmount === '' || facility.accepted_amount === this.filters.acceptedAmount)
        );
      } else {
        this.filteredFacilities = this.facilities.filter(facility => {
          const distance = this.calculateDistance(this.mapCenter, { lat: facility.latitude, lng: facility.longitude });
          return distance < this.radius * 1000 &&
            (!this.filters.recycle || facility.recycle) &&
            (!this.filters.disposal || facility.disposal) &&
            (!this.filters.acceptEPS || facility.accept_EPS) &&
            (!this.filters.acceptConsumerPolystyrene || facility.accept_consumer_polystyrene) &&
            (this.filters.acceptedAmount === '' || facility.accepted_amount === this.filters.acceptedAmount);
        });
      }
    },
    
    applyFilters() {
      if (!this.userLocation) {
        alert('Please enter a location before applying filters.');
        return;
      }
      this.filterFacilities();
      this.showFilters = false;
    },
    
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const newLocation = { 
            lat: position.coords.latitude, 
            lng: position.coords.longitude 
          };
          
          if (this.isWithinYarraRange(newLocation)) {
            this.mapCenter = newLocation;
            this.userLocation = this.mapCenter;
            this.loadingPostcode = true;
            this.searchQuery = ''; 
          } else {
            alert("Your current location is outside the Yarra River area. Some facilities may not be available.");
            this.mapCenter = newLocation;
            this.userLocation = this.mapCenter;
            this.loadingPostcode = true;
            this.searchQuery = '';
          }
        }, error => {
          console.error('Error getting location:', error);
          this.loadingPostcode = false;
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
    calculateDistance(point1, point2) {
      const R = 6371e3; // Earth's radius in meters
      const φ1 = this.toRadians(point1.lat);
      const φ2 = this.toRadians(point2.lat);
      const Δφ = this.toRadians(point2.lat - point1.lat);
      const Δλ = this.toRadians(point2.lng - point1.lng);

      const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ/2) * Math.sin(Δλ/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

      return R * c; // Distance in meters
    },
    toRadians(degrees) {
      return degrees * Math.PI / 180;
    },
    isWithinYarraRange(location) {
      const distance = this.calculateDistance(this.yarraCenter, location);
      return distance <= this.maxRadius;
    },
    selectFacility(facility, event) {
      if (event && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
      this.selectedFacility = facility;
    },
    closeInfoWindow() {
      this.selectedFacility = null;
    },
    showNavigationModal() {
      if (!this.userLocation) {
        alert("Please set your location before navigating.");
        return;
      }
      this.showModal = true;
    },
    getDirections() {
      if (!this.userLocation || !this.selectedFacility) {
        alert("Please set your location and select a facility before getting directions.");
        return;
      }

      this.$router.push({
        name: 'NavigationPage',
        query: {
          userLat: this.userLocation.lat,
          userLng: this.userLocation.lng,
          destLat: this.selectedFacility.latitude,
          destLng: this.selectedFacility.longitude,
          destName: this.selectedFacility.name,
          travelMode: this.travelMode
        }
      });
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.custom-autocomplete-wrapper {
  width: 100%;
  max-width: 300px;
}

.custom-autocomplete {
  width: 100%;
}

.custom-autocomplete :deep(.el-input__wrapper) {
  background-color: transparent;
  box-shadow: none !important;
  padding: 1px;
  border-radius: 20px;
  border: 2px solid #3498db;
}

.custom-autocomplete :deep(.el-input__inner) {
  height: 36px;
  line-height: 36px;
  border: none;
  background-color: transparent;
  padding-left: 35px;
  border-radius: 20px;
}

.custom-autocomplete :deep(.el-input__prefix) {
  left: 12px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.el-autocomplete-suggestion {
  border-radius: 4px;
  margin-top: 5px;
}

.info-link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.info-link:hover {
  color: #2980b9;
}


.site-footer {
  padding: 20px;
  position: relative;
}

.diy-tools-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-family: 'Inter', sans-serif;
}

.diy-tools-button:hover {
  background-color: #5cacee;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.diy-tools-button i {
  margin-left: 8px;
}
.header {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 100;
}

.back-button {
  padding: 10px 15px;
  background-color: #87ceeb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  margin-top: 0px;
}

.back-button:hover {
  background-color: #007bff;
  transform: translateY(-2px);
}

.heading1 {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  margin: 0;
  font-size: 2.2em;
  text-align: center;
  flex-grow: 1;
}


/* Knowledge baord style */
.knowledge-board {
  position: relative;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  color: #333333; 
  padding: 30px;
  border-radius: 12px;
  margin-bottom: 30px;
  overflow: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.70);  /* white transparents background */
  z-index: 1;
}

.content-container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.text-section {
  flex: 1;
  padding-right: 20px;
  margin-left: 300px;
  font-family: 'Inter', sans-serif;
}

.text-section ul {
  padding-left: 20px;
  margin: 0;
  list-style-type: disc;
}

.text-section li {
  margin-bottom: 10px;
  text-align: left;
  padding-left: 5px;
}

.knowledge-board h2 {
  font-size: 24px;  
  margin-bottom: 20px;
  color: #3498db;  
  font-weight: bold;
}

.icon {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  vertical-align: middle;
}

.image-section {
  flex: 0 0 auto;
  max-width: 170px;
  margin-left: 60px;
  order: -1; 
}


/* map control css */
.map-controls {
  display: flex;
  justify-content: center;
  z-index: 1000;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 800px;
  margin: 0 auto;
}

.location-input {
  flex: 0 1 auto;
  margin-right: 10px;
  width: 300px;
}

.location-input .autocomplete {
  width: 100%;
}

.location-input .input-field {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border: 2px solid #3498db;
  border-radius: 20px;
  box-sizing: border-box;
  outline: none;
  transition: all 0.3s ease;
}

.location-button {
  flex: 0 0 auto;
  margin-right: 10px;
  white-space: nowrap;
}

.location-input .autocomplete .pac-container {
  z-index: 1050;
  width: 300px !important; 
}
.pac-container {
  z-index: 1050; 
  position: relative;
}

.location-input .autocomplete .input-field:focus {
  border-color: #3498db;
  
}

/* fiter window css */
.filters-dropdown {
  position: relative;
}

.filters-content {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
  z-index: 1000;
  width: 320px;
  margin-top: 10px;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.filter-item {
  margin-bottom: 15px;
}

.filter-item label {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.filter-item input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.select-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
  transition: border-color 0.3s ease;
}

.select-input:focus {
  border-color: #3498db;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.slider-container label {
  margin-bottom: 10px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.8;
  transition: opacity .2s;
  margin-bottom: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%; 
  background: #3498db;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
}

.slider-value {
  text-align: center;
  font-weight: bold;
  color: #3498db;
}

/* map and facities css*/
.map-container {
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 500; /* Make sure the map is below the control */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 5px solid #e6f7ff;
  position: relative;
}

.facility-info {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 320px;
  background: rgba(255, 255, 255, 0.95);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  max-height: 480px;
  overflow-y: auto;
  color: #333;
  text-align: left;
  border: 1px solid #ddd;
  transition: all 0.3s ease;
}

.facility-info h2 {
  color: #3498db;
  font-size: 18px;
  margin-bottom: 10px;
}

.facility-info p {
  font-size: 14px;
  margin-bottom: 5px;
}

.facility-info p strong {
  font-weight: bold;
  color: #333;
}

.facility-info .hint-text {
  color: #3498db;
  font-style: italic;
  font-size: 0.9em;
  margin-top: 10px;
  padding: 10px;
  border-left: 3px solid #3498db;
  background-color: #f0f8ff;
  border-radius: 0 5px 5px 0;
  position: relative;
  padding-left: 30px;
}



.facility-info .hint-text a {
  color: #2980b9;
  text-decoration: none;
  font-weight: bold;
}

.facility-info .hint-text a:hover {
  text-decoration: underline;
}

.blue-button, .red-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: background-color 0.3s ease, transform 0.1s ease;
  white-space: nowrap;
  margin-left: 20%;
}

.blue-button {
  background-color: #3498db;

}


.red-button {
  background-color: #ec7063;
}

.blue-button:hover, .red-button:hover {
  transform: translateY(-2px);
}

.blue-button:active, .red-button:active {
  transform: translateY(1px);
}

.filter-button {
  flex-shrink: 0;
  margin-left: 10px;
}

.blue-button:hover {
  background-color: #2980b9;
}

.red-button:hover {
  background-color: #e74c3c;
}

.input-group button {
  margin-left: 10px;
}

.main-title {
  font-family: 'Patua One', sans-serif;
  color: #2c3e50;
  margin: 0 0 20px 0;
  padding: 10px;
  font-size: 2.2em;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5); 
  margin-right:24%;
}

.title-word {
  display: inline-block;
  background: linear-gradient(45deg, #92D3FB, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold;
  position: relative;
  transition: all 0.3s ease;
}

.title-word::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.title-word:hover {
  transform: translateY(-2px);
}

.title-word:hover::after {
  transform: scaleX(1);
}

/* hint text css */
.hint-text {
  color: #3498db;
  font-style: italic;
  font-size: 0.9em;
  margin-top: 10px;
  padding: 10px;
  border-left: 3px solid #3498db;
  background-color: #f0f8ff;
  border-radius: 0 5px 5px 0;
  position: relative;
  padding-left: 30px;
}

.hint-text {
    position: relative;
    color: #3498db;
    font-style: italic;
    font-size: 0.9em;
    margin-top: 10px;
    padding: 10px 10px 10px 30px; 
    border-left: 3px solid #3498db;
    background-color: #f0f8ff;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
}

.hint-icon {
    position: absolute;
    left: 10px; 
    width: 18px; 
    height: 18px;
    vertical-align: middle;
}


.hint-text a {
  color: #2980b9;
  text-decoration: none;
  font-weight: bold;
}

.hint-text a:hover {
  text-decoration: underline;
}

.knowledge-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: 88px;
  margin-top: 28px;
}
/* Styles for modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.travel-mode-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.blue-button, .red-button {
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: background-color 0.3s ease, transform 0.1s ease;
  white-space: nowrap;
}

.blue-button {
  background-color: #3498db;
}

.red-button {
  background-color: #ec7063;
}

.blue-button:hover {
  background-color: #2980b9;
}

.red-button:hover {
  background-color: #e74c3c;
}


@media (max-width: 768px) {
  .map-controls {
    flex-direction: column;
  }

  .main-title {
    font-size: 2rem;
  }

  .location-input {
    width: 100%;
  }
  
  .location-input .input-field {
    width: 100%;
  }

  .map-container {
    height: 300px;
  }

  .filters-content {
    width: 100%;
    right: auto;
    left: 0;
  }

  .facility-info {
    width: 80%;
    max-height: 200px;
  }
}
</style>